import React, {Component} from 'react';
import Styles from './PhotoView.module.scss';
import InnerWrap from "../component/layout/InnerWrap";
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";
import Logo from "../component/layout/Logo";

import Footer from "../component/layout/Footer";
import EbackFreeCall from "../component/layout/EbackFreeCall";
import appStore from "../store/AppStore";

import {makeObservable, observable} from "mobx";
import {Img} from 'react-image';
import axios from "axios";
import {APP_PUBLIC_PATH} from "../constant/AppConst";
import InfiniteScroll from "react-infinite-scroller";
import {PhotoStore, photoStore} from "../store/PhotoStore";
import {Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import {Call, Link} from "iconsax-react";

import Lottie from "lottie-react";
import LoaderLottie from './data.json'
import {navigate} from "../helper/HistoryHelper";
import {FaSearch} from "react-icons/fa";
import ThumbImage from "../component/image/ThumbImage";

@observer
class PhotoView extends Component {

    componentDidMount() {
        appStore.isModalOpen = false;
        appStore.isOpen = false;
        if(photoStore.isInit){
            photoStore.debounceRefresh();
        }
        // if(photoStore.nextPhoto){
        //     let target = document.getElementById(`PHOTO_${photoStore.nextPhoto?.id}`)
        //     console.log(target)
        //     setTimeout(()=>{
        //         target.scrollIntoView({ behavior:"smooth", block: "center", inline: "nearest" });
        //     },100)
        //
        // }else{
        setTimeout(()=>{
            window.scrollTo(0, photoStore.scrollTop)
        },100)

        // }
    }

    constructor(props) {
        super(props);
    }


    render() {
        let store = photoStore;
        let nextPhoto = store?.nextPhoto;
        return (
            <div className={Styles.PhotoView}>

                <div className={Styles.SearchHeader}>
                    <nav className={Styles.Header}>
                        <InnerWrap>
                            <nav className={Styles.TopMenu}>
                                <Logo
                                    logoName={'이백인테리어'}
                                    logoNameColor={'#263238'}
                                    doorColor={'#263238'}
                                    iconNavigateUrl={'/main'}
                                    characterNavigateUrl={'/main'}
                                />
                                <div className={`${Styles.Navigation} ${appStore.isOpen ? Styles.Active : ''}  `}>
                                    <ul>
                                        <li>

                                            <div className={Styles.LinkMenu} onClick={() => {
                                                navigate('/company')
                                                window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                            }}>회사소개
                                            </div>
                                        </li>
                                        <li>
                                            <div className={Styles.LinkMenu} onClick={() => {
                                                navigate('/sink')
                                                window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                            }}>자재선택
                                            </div>
                                        </li>
                                        <li>
                                            <div className={Styles.LinkMenu} onClick={() => appStore.openModal()}>실측예약

                                            </div>
                                        </li>
                                        <li>
                                            <div className={Styles.LinkMenu}

                                                 onClick={() => {
                                                     navigate('/search/photo')
                                                     window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                                 }}>현장사진

                                            </div>
                                        </li>
                                    </ul>
                                    <EbackFreeCall/>
                                </div>
                            </nav>
                        </InnerWrap>
                    </nav>

                    <Container className={Styles.SearchBar}>
                        <InputGroup hasValidation className={`position-relative ${Styles.InputContainer}`}>
                            <div className={Styles.Icon}>

                                <FaSearch/>
                            </div>
                            <Form.Control
                                placeholder={'Search'}
                                size={'lg'}
                                value={photoStore.keyword}
                                onChange={e => {
                                    photoStore.keyword = e.target.value
                                }}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        window.scrollTo(0, 0)
                                        store.searchCondition.set("keyword", photoStore.keyword)
                                        store.debounceFetch(true)
                                    }
                                }}
                                className={`${Styles.Input}`}

                            />
                            <Form.Control.Feedback type="invalid">

                            </Form.Control.Feedback>
                        </InputGroup>
                    </Container>

                </div>
                <main>
                    <div className={Styles.PhotoContent}>
                        <div className={Styles.Scroll}>

                            <InfiniteScroll
                                pageStart={0}
                                loadMore={(page) => {
                                    store.debounceFetch(false)
                                }}
                                hasMore={store.photos.length < store.count}
                                initialLoad
                                threshold={250}
                                loader={
                                    <div className="loader d-flex align-items-center justify-content-center" key={0}>
                                        <Lottie animationData={LoaderLottie} style={{width: 64, height: 64}}/>
                                    </div>
                                }
                                useWindow={true}

                            >
                                <Container>
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={{576: 2, 768: 2, 992: 3, 1200: 4, 1400: 5}}

                                    >
                                        <Masonry className={Styles.MasonryContainer}>
                                            {
                                                store.photos.map((photo, index) => {

                                                    return (
                                                        <div className={`${Styles.Item} ${photo?.id === nextPhoto?.id ? Styles.Active : ''}`}
                                                             key={`PHOTO_${photo?.id}`}
                                                             id={`PHOTO_${photo?.id}`}
                                                             onClick={()=>{
                                                                 store.scrollTop = window.scrollY;
                                                                 store.nextPhoto = photo;
                                                                 navigate(`/photo/detail/${photo?.id}`)
                                                             }}
                                                        >
                                                            <ThumbImage src={`${APP_PUBLIC_PATH}/${photo?.url}`} alt={''}
                                                                 style={{height:store.heightMap.get(photo?.id)||'auto'}}
                                                                 onLoad={e => {
                                                                     store.heightMap.set(photo?.id, e.target.height)
                                                                 }}
                                                            />
                                                            <div className={Styles.Cover}>
                                                                {
                                                                    photo.blogUrl &&
                                                                    <Button variant={"light"} className={Styles.Link}
                                                                            onClick={() => {
                                                                                window.open(photo.blogUrl, '_blank')
                                                                            }}
                                                                    >
                                                                        <Link variant="Bold"/>
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </Container>
                            </InfiniteScroll>
                        </div>
                    </div>

                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>


        )
            ;
    }
}

PhotoView.propTypes = {};

export default PhotoView;