import React from "react";
import Styles from "./PetColorChip.module.scss";

class PetColorChip extends React.Component {
    children;

    render() {
        let {colorName, children} = this.props;
        return (
            <div className={Styles.ImageChanger}>
                <div className={Styles.ImageWrap}>
                    {children}
                </div>
                <div className={Styles.ColorName}>{colorName}</div>
            </div>
        );
    }
}

export default PetColorChip;