import React from 'react';
import {computed, makeObservable, observable, reaction, values} from "mobx";
import axios from "axios";
import CommonHelper from "../helper/CommonHelper";
import Sort from "./Sort";
import {debounce} from "lodash";

class PhotoStore {

    constructor() {
        makeObservable(this);

    }

    @observable isInit = true;

    @observable page = 1;

    @observable isFetching = false;

    /**
     * 정렬 컬럼
     * @type Sort
     */
    @observable sort= new Sort('createdAt', Sort.SORT_DIRECTION.DESC);

    /**
     * 현재 검색 조건
     * @type {Map}
     */
    @observable searchCondition = new Map();

    /**
     * 페이징 블럭 사이즈
     * @type {number}
     */
    paginationSize;

    /**
     * 페이지당 행 수
     * @type {number}
     */
    rowsPerPage = 40;

    @computed
    get searchConditionWithPage() {
        let m = CommonHelper.mapToObject(this.searchCondition);
        m.pagingOption = {
            page: this.page,
            rowsPerPage: this.rowsPerPage,
            sort: this.parameterizeSort
        };
        return m;
    }

    @computed
    get parameterizeSort() {
        return this.sort.toParam()
    }

    debounceFetch = debounce((reset) => {
        this.fetch(reset)
    }, 500)

    debounceRefresh = debounce(() => {
        this.searchCondition.clear();
        this.fetch(true)
    }, 500)

    fetch = async (reset = true) => {
        let store = this;
        if (store.isFetching)
            return;
        if(reset){
            store.clear()
            store.page = 1;
        }else{
            store.page+=1;
        }
        store.isFetching = true;
        let {data: {items, rowsCount}} = await axios.post(`/image/search`, this.searchConditionWithPage)
        store.isFetching = false;
        let originPhotos = store.photos
        store.photoMap.replace(originPhotos.concat(items).map(photo => [photo.id, photo]))
        store.count = rowsCount;
        store.isInit = false;
        return originPhotos.concat(items).map(photo => [photo.id, photo]);
    }

    @computed
    get isMore() {
        let store = this;
        return store.photos.length < store.count;
    }

    @observable
    photoMap = new Map();

    @computed
    get photos() {
        return values(this.photoMap) || [];
    }

    @observable
    count = 0;

    @observable
    scrollTop = 0;

    @observable
    selectedPhoto;

    @observable
    nextPhoto;

    @observable
    keyword;

    clear() {

        this.photoMap.clear();
    }

    scrollParentRef = React.createRef();

    @observable
    heightMap = new Map();

    @observable
    detailStoreMap = new Map();
}


const photoStore = new PhotoStore();
photoStore.debounceRefresh()
export {photoStore, PhotoStore};
