import React from "react";
import Styles from "./EbakModalButton.module.scss";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";

@observer
class EbakModalButton extends React.Component {

    render() {
        let {label, children} = this.props;
        return (
                <button className={Styles.EbakButton} onClick={()=> AppStore.isModalOpen = !AppStore.isModalOpen}>
                    {children}
                    {label}
                </button>
        );
    }
}

export default EbakModalButton;