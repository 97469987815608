import React, {Component} from 'react';
import Styles from './EbackFreeCall.module.scss';
import {observer} from "mobx-react";
import {Button, Dropdown, Image} from "react-bootstrap";
import {Call} from "iconsax-react";
import {loginDialogStore} from "../../store/LoginDialogStore";
import appStore from "../../store/AppStore";


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        className={Styles.CustomToggle}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <div className={Styles.Avatar}>
            <Image src={appStore.avatar}/>
        </div>
    </div>
));
@observer
class EbackFreeCall extends Component {
    componentDidMount() {
    }

    /*constructor(props) {
        super(props);
    }*/

    render() {

        return (
            <div className={Styles.EbackCall}>
                <Button variant={"primary"}
                        style={{color:'#fff'}}
                        className={`ms-2 me-2`}
                        onClick={()=>{
                            window.location.href = `tel:010-4298-2102`
                        }}
                >
                    <Call size={`1.2rem`}/> 010-4298-2102
                </Button>

                {
                    appStore.isLogin ?
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components"/>
                            <Dropdown.Menu>
                                <Dropdown.Item className={``}
                                               onClick={e=>{
                                                   appStore.fetchLogout()
                                               }}
                                >
                                    로그아웃
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        :
                        <></>
                        // <Button variant={"primary"}
                        //         style={{color:'#fff'}}
                        //         onClick={()=>{
                        //             loginDialogStore.open()
                        //         }}
                        // >
                        //     로그인
                        // </Button>
                }
            </div>
        );
    }


}

EbackFreeCall.propTypes = {};

export default EbackFreeCall;