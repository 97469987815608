import React from "react";
import Styles from "./EbakModalButtonOtherStyle.module.scss";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";

@observer
class EbakModalButtonOtherStyle extends React.Component {

    /*constructor(props) {
        super(props);

    }*/
    render() {
        let {label} = this.props;
        return (
            <div className={Styles.ButtonWrap}>
                <button className={Styles.EbakButtonCall} onClick={() => AppStore.isModalOpen = !AppStore.isModalOpen}>
                    <img className={Styles.Icon} src={"/asset/img/randing/iconsax/BoxSearch-Outline-white.svg"} alt={''} />
                    {label}
                </button>
            </div>
        );
    }
}

export default EbakModalButtonOtherStyle;