import React, {Component, useEffect, useState} from 'react';
import {Button, Image, Modal} from "react-bootstrap";
import {observer} from "mobx-react";
import {useGoogleLogin} from "@react-oauth/google";
import FacebookLogin from '@greatsumini/react-facebook-login';
import {TiSocialFacebookCircular} from "react-icons/ti";
import Styles from './LoginDialog.module.scss'
import {Moon} from "iconsax-react";
import axios from "axios";
import appStore from "../../store/AppStore";
import {MdClear, MdRemove} from "react-icons/md";

const LoginDialog = observer(({store}) => {

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await new Promise(resolve => {
                const xhr = new XMLHttpRequest();

                xhr.open('GET', `https://www.googleapis.com/oauth2/v3/userinfo`);
                xhr.setRequestHeader('Authorization', `Bearer ${tokenResponse.access_token}`)
                xhr.onload = function () {
                    if (this.status >= 200 && this.status < 300)
                        resolve(JSON.parse(this.responseText));
                    else resolve({ err: '404' });
                };
                xhr.send();
            });

            snsLogin({
                name:userInfo?.name,
                loginId:userInfo?.sub,
                avatar:userInfo?.picture
            })

        },
        onError: e=>{
            console.log(e)
        }
    });

    const snsLogin = (user) =>{
        console.log(user)
        appStore.fetchSnsLogin({
            form:{
                ...user
            },
        })
        store.close();
    }


    return (
        <Modal show={store.show}
               centered={true}
               onHide={()=>{
                    store.close()
               }}
        >
            <Modal.Body>
                <div className={`d-flex justify-content-end`}>
                    <div style={{cursor:'pointer'}}
                         onClick={()=>{
                             store.close()
                         }}
                    >
                        <MdClear size={`1.5rem`}/>
                    </div>
                </div>
                <div className={Styles.LoginDialog}>

                    <div className={Styles.Logo}>
                        <Image src={`/asset/img/login-logo.jpg`}/>
                    </div>
                    <div className={Styles.Title}>
                        이백인테리어에 오신 것을
                        환영합니다.
                    </div>
                    <div className={Styles.Buttons}>
                        <Button variant={"danger"}
                                className={`mb-2 p-3`}
                                onClick={login}
                        >
                            Google 로그인
                        </Button>

                        <FacebookLogin
                            appId="520684823302509"
                            onSuccess={(response) => {
                                console.log('Login Success!', response);
                            }}
                            onFail={(error) => {
                                console.log('Login Failed!', error);
                            }}
                            onProfileSuccess={(response) => {

                                snsLogin({
                                    name:response?.name,
                                    loginId:response?.id,
                                    avatar:response?.picture?.data?.url
                                });
                            }}
                            render={({ onClick }) =>
                                <Button variant={"primary"}
                                        className={`mb-2 d-flex p-3`}
                                        onClick={onClick}
                                        style={{color:'#fff'}}
                                >
                                    Facebook 앱으로 로그인
                                </Button>
                            }
                        />

                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );

});

export {LoginDialog};
