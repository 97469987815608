import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from './CostCalculationView.module.scss';
import {Button, Col, Container, Form, InputGroup, Row,} from 'react-bootstrap';
import {FormStore, Input, Validation} from '../component/validation';
import {action, computed, makeObservable, observable, /*reaction,*/ toJS} from 'mobx';
import axios from 'axios';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import PostCode, {postCodeStore} from '../component/dialog/PostCode';
import NumberInput from '../component/input/NumberInput';
import InnerWrap from "../component/layout/InnerWrap";
import AppStore from "../store/AppStore";
import ModalSection from "../component/layout/ModalSection";
import {Add} from "iconsax-react";

const costStore = new FormStore();
const InputWithLabel = ({
                            label,
                            placeHolder = '입력하세요',
                            store,
                            name,
                            validations,
                            helperText,
                            as = 'input',
                            onlyNumber,
                            format,
                            disabled
                        }) => {

    return (
        <div className={Styles.InputWithLabel}>
            <Form.Label className={Styles.Label}>{label}</Form.Label>
            {
                onlyNumber ?
                    <NumberInput placeholder={placeHolder}
                                 as={as}
                                 size="lg"
                                 name={name}
                                 store={store}
                                 validations={validations}
                                 format={format}
                                 thousandSeparator
                                 validOnBlur
                                 mask="_"
                                 disabled={disabled}
                    />
                    :
                    <Input placeholder={placeHolder}
                           as={as}
                           size="lg"
                           name={name}
                           store={store}
                           validations={validations}
                           validOnBlur
                           disabled={disabled}
                    />
            }
            <Form.Text className={Styles.Helper}>
                {helperText}
            </Form.Text>
        </div>
    );
};
InputWithLabel.prototype = {
    validations: PropTypes.arrayOf(PropTypes.instanceOf(Validation))
};

/*
const SectionLabel = ({TitleText, DescriptionText}) => {

    return (
        <div className={Styles.SectionLabel}>
            <div className={Styles.Title}>
                {TitleText}
            </div>
            <div className={Styles.Description}>
                {DescriptionText}
            </div>
        </div>
    );
};
*/

/*const Banners = ({TitleText, SubText, Tag}) => {

    return (
        <div className={Styles.Banners}>
            <div className={Styles.TitleText}>
                {TitleText}
            </div>
            <div className={Styles.SubText}>
                {SubText}
            </div>
            <div className={Styles.Tag}>
                {Tag}
            </div>
        </div>
    );
};*/


/*@observer
class Item extends Component {

    @observable
    selectedOption = this.props.options?.[0];
    dispose1;
    dispose2;

    constructor(props, context) {
        super(props, context);
        makeObservable(this);
    }

    componentDidMount() {
        const {id, store} = this.props;
        store.setValue(`items.${id}.selectedOption`, this.selectedOption);
        store.setValue(`items.${id}.disabled`, false);

        this.dispose1 = reaction(
            () => store.valueMap.get(`items.${id}.quantity`),
            (quantity) => {
                const amount = (quantity || 0) * (this.selectedOption?.price || 0);
                store.setValue(`items.${id}.amount`, amount);
            });

        this.dispose2 = reaction(
            () => this.selectedOption?.price,
            (price) => {
                const amount = (store.valueMap.get(`items.${id}.quantity`) || 0) * (price || 0);
                store.setValue(`items.${id}.amount`, amount);
            });
    }

    componentWillUnmount() {
        this.dispose1();
        this.dispose2();
    }

    @action.bound
    handleCheckOption(e, option) {
        const {id, store} = this.props;
        this.selectedOption = option;
        store.setValue(`items.${id}.selectedOption`, option);
    }


    @action.bound
    handleDisable(e) {
        const {id, store} = this.props;
        store.setValue(`items.${id}.disabled`, !e.target.checked);
    }


    render() {
        const {id, name, store, options} = this.props;
        const checked = !store.valueMap.get(`items.${id}.disabled`);
        return (
            <div className={Styles.Items}>
                <Form.Check type="checkbox"
                            label={name}
                            onChange={this.handleDisable}
                            checked={checked}
                            className={Styles.ItemName}
                />
                <Form.Group>
                    <InputWithLabel label="사이즈를 입력하세요"
                                    placeHolder="입력하세요"
                                    helperText="단위는 M(미터) 입니다"
                                    store={store}
                                    name={`items.${id}.quantity`}
                                    onlyNumber
                                    disabled={!checked}
                    />
                    <div className={`${Styles.RadioLabelText} ${options.length < 2 ? Styles.DisplayNone : ''}`}>
                        {
                            options.map((option, idx) => {
                                const {name, id: optionId,} = option;
                                return (
                                    <Form.Check id={`item-${id}-${optionId}`}
                                                key={`item-${id}-${optionId}`}
                                                type="radio"
                                                name={`item-${id}`}
                                                label={name}
                                                checked={optionId === this.selectedOption?.id}
                                                onChange={(e) => this.handleCheckOption(e, option)}
                                                disabled={!checked}
                                    />
                                );
                            })
                        }
                    </div>
                </Form.Group>
            </div>
        );
    }
}*/


@observer
class CostCalculationView extends Component {



    @observable
    forms = [];

    constructor(props, context) {
        super(props, context);
        makeObservable(this);
    }

    @computed
    get estimatedAmount() {
        const {items} = costStore.values;
        if (!items)
            return 0;

        const entries = Object.entries(items);
        const acc = entries.reduce((acc, [, v]) => {
            return acc + (v?.disabled ? 0 : (v?.amount || 0));
        }, 0);
        return acc;
    }

    @action.bound
    handleFormsUpdate = (forms) => {
        this.forms.replace(forms);
    };

    componentDidMount() {
        window.toJS = toJS;
        window.store = costStore;
        window.forms = this.forms;
        costStore.validationMap.set('user.address1', [new Validation(Validation.REQUIRED_REGEX, '주소를 선택해주세요.')]);
        axios.get('/item/items')
            .then(({data}) => {
                this.handleFormsUpdate(data);
            });

    }

    handleOnSendInvoice = () => {
        if (!costStore.doCheckValid(false)) {
            return;
        }

        const {/*items,*/ user, content} = costStore.values;
        const data = {
            user: {
                ...user,
                address: `${user.address1} ${user.address2}`
            },
            content
        };
        axios.post('/invoice/send', data)
            .then(() => {
                toast.info('전송되었습니다 ^^');
                const items = this.forms.map(({id, options}) => [`items.${id}.selectedOption`, options[0]]);
                costStore.clear();
                items.forEach(([k, v]) => {
                    costStore.valueMap.set(k, v);
                });
                AppStore.isModalOpen = !AppStore.isModalOpen;
            });
    };

    handleSelectAddress = (_, fullAddress) => {
        costStore.setValueWithTry('user.address1', fullAddress);
    };

    render() {
        const addres1Message = costStore.hasTried.get('user.address1') && costStore.getErrorMessages('user.address1')?.[0];
        return (
            <div className={`${Styles.CostCalculationViewClass} ${AppStore.isModalOpen? Styles.On:''}`}>
                <div className={Styles.ViewWrap}>
                    <div className={Styles.CloseButton}>
                        <p onClick={()=> AppStore.isModalOpen = !AppStore.isModalOpen}><Add size={'2rem'} /></p>
                    </div>
                    <div className={Styles.MainContent}>
                        <ModalSection>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.ContentTitle}>

                                        <div className={Styles.TextTitle}>
                                            <h1>무료 실측예약</h1>
                                            <div className={Styles.RegularText}>
                                                <p>#무료방문상담 #친절상담 #매일확인</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={Styles.Article}>
                                        <Container>
                                            <Row>
                                                <Col md={12}>
                                                    <div className={Styles.Invoice}>
                                                        <Form>
                                                            <InputWithLabel label="고객명"
                                                                            placeHolder="입력하세요"
                                                                            store={costStore}
                                                                            name="user.name"
                                                                            validations={[
                                                                                new Validation(Validation.REQUIRED_REGEX, '고객명을 입력하세요')
                                                                            ]}

                                                            />

                                                            <InputWithLabel label="연락처"
                                                                            placeHolder="입력하세요"
                                                                            store={costStore}
                                                                            name="user.tel"
                                                                            validations={[
                                                                                new Validation(Validation.REQUIRED_REGEX, '연락처를 입력하세요'),
                                                                                new Validation(/^\d+$/, '숫자만 입력해주세요')
                                                                            ]}
                                                            />

                                                            <div className={Styles.InputWithLabel}>
                                                                <Form.Label className={Styles.Label}>현장주소</Form.Label>
                                                                <InputGroup className="mb-1">
                                                                    <Form.Control placeholder="입력하세요"
                                                                                  disabled
                                                                                  size="lg"
                                                                                  value={costStore.valueMap.get('user.address1')}
                                                                                  isInvalid={!!addres1Message}
                                                                    />
                                                                    <Form.Control.Feedback>주소를 선택해
                                                                        주세요</Form.Control.Feedback>
                                                                    <Button
                                                                        style={{color:'#fff'}}                                                                        onClick={() => {
                                                                        postCodeStore.open(this.handleSelectAddress);
                                                                    }}>
                                                                        주소선택
                                                                    </Button>
                                                                </InputGroup>
                                                                <Input store={costStore} name="user.address2"
                                                                       placeholder="입력하세요"
                                                                       validations={[
                                                                           new Validation(Validation.REQUIRED_REGEX, '상세주소를 입력해주세요.')

                                                                       ]}
                                                                />
                                                                <PostCode/>
                                                            </div>


                                                            <InputWithLabel as="textarea"
                                                                            label="내용"
                                                                            placeHolder="입력하세요"
                                                                            store={costStore}
                                                                            name="content"
                                                            />
                                                        </Form>
                                                        <Button type="button"
                                                                size="lg"
                                                                style={{color:'#fff'}}                                                  className="w-100 bg-primary"
                                                                onClick={this.handleOnSendInvoice}>요청하기</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </InnerWrap>
                        </ModalSection>
                    </div>
                </div>
            </div>
        );
    }
}

export {CostCalculationView as default, costStore};
