import {action, computed, makeObservable, observable} from 'mobx';
import {MENU_GROUP_RE} from '../constant/AppConst';
import Cookies from 'js-cookie';
import * as moment from 'moment';
import axios from 'axios';
import {navigate} from "../helper/HistoryHelper";
import {toast} from "react-toastify";
import {costStore} from "../view/CostCalculationView";

const AUTH = 'authKey';
const ID = 'id';
const EMAIL = 'email';
const IS_SAVE = 'save';
const USER_ID = 'userId';
const USER_NAME = 'userName';
const IS_ADMIN = 'admin';

export const CookieKeys = {AUTH, ID, USER_ID, USER_NAME, IS_ADMIN, EMAIL, IS_SAVE};
axios.interceptors.response.use((response) => response, (error) => {
    let response = error.response;
    if(response){
        if(response.data){
            console.log(response)
            toast.error(response.data.message)
        }
        if(response.status === 403){
            appStore.isLogin = false;
            navigate("/login")
        }
    }
    throw error;
});

/**
 * 앱 전반에 관련된 상태
 */
class AppStore {

    constructor() {
        makeObservable(this);
        /*setInterval(
            ()=>this.now = moment()
                ,1000
        )*/
    }
    @observable
    onLightBoxSink = false;
    @observable
    onLightBoxSlideSink = 1;
    @observable
    onLightBoxDress = false;
    @observable
    onLightBoxSlideDress = 1;

    @observable
    isSampleOpen = false;

    @observable
    isModalOpen = false;

    @observable
    isOpen = false;

    @observable
    isStart = false;

    failedPath = '/';

    @observable
    now = moment();

    @observable
    isLogin = false;

    @observable
    userId = '';

    @observable
    userEmail = '';

    @observable
    userName = Cookies.get(CookieKeys.USER_NAME);

    @observable
    userProfileUrl = Cookies.get(CookieKeys.USER_PROFILE_URL);

    @observable
    permissions = [{id: '/login'}];

    @observable
    isAdmin = false;

    @observable
    isSuper = false;

    @observable
    currentPath = '/';

    @observable
    previousPath = '/';

    @observable
    sideBarOpen = false;

    @observable
    isSessionChecked = false;

    @observable
    loginId = '';

    @observable
    type = '';

    @observable
    avatar = '';

    @computed
    get currentMenuGroupActive() {
        let matched = appStore.currentPath.match(MENU_GROUP_RE);
        if (matched && matched.length > 1)
            return matched[1];
        else
            return 'people';
    }

    @action.bound
    toggleSideBar() {
        this.sideBarOpen = !this.sideBarOpen;
        Cookies.set(CookieKeys.SIDEBAR_OPEN, this.sideBarOpen);
    }

    @observable
    isUpdatingSession = false;

    @action.bound
    updateSession({id, name, email, type, loginId, avatar}) {
        this.userId = id;
        this.userEmail = email;
        this.userName = name;
        this.loginId = loginId;
        this.type = type;
        this.avatar = avatar;
        Cookies.set(CookieKeys.ID, id);
        Cookies.set(CookieKeys.USER_NAME, name);
        this.isLogin = true;


        if (type === 'ADMIN' || type === 'SUPER'){
            this.isAdmin = true;
            if (type === 'SUPER')
                this.isSuper = true;
            else
                this.isSuper = false;
        }else{
            this.isAdmin = false;
        }
    }

    @action.bound
    clearSession() {
        this.userId = '';
        this.userEmail = '';
        this.userName = '';
        this.userProfileUrl = '';
        Cookies.remove(CookieKeys.ID);
        Cookies.remove(CookieKeys.USER_NAME);
        this.isLogin = false;
    }


    /**
     * 로그인을 요청한다.
     * @param form 로그인폼
     * @param success 성공콜백
     * @paraam fail 실패콜백
     */
    @action.bound
    async fetchLogin({form, success, fail, beforeSend, complete}) {
        beforeSend && beforeSend();
        this.isUpdatingSession = true;
        axios.post('/user/login', form, { withCredentials: true }).then(response => {
            let data = response.data;
            this.updateSession({
                id : data.id,
                name : data.name,
                email : data.email,
                loginId : data.loginId,
                type : data.type
            })
            success && success(data);
        }).catch(function (error) {
            fail && fail(error)
        }).finally(() => {
            complete && complete();
            this.isUpdatingSession = false;
        });
    }

    @action.bound
    async fetchSnsLogin({form, success, fail, beforeSend, complete}) {
        beforeSend && beforeSend();
        this.isUpdatingSession = true;
        axios.post('/user/sns/login', form, { withCredentials: true }).then(response => {
            let data = response.data;
            this.updateSession({
                id : data.id,
                name : data.name,
                email : data.email,
                loginId : data.loginId,
                type : data.type,
                avatar : data.avatar
            })
            success && success(data);
        }).catch(function (error) {
            fail && fail(error)
        }).finally(() => {
            complete && complete();
            this.isUpdatingSession = false;
        });
    }

    /**
     * 로그아웃
     */
    @action.bound
    fetchLogout() {
        axios.post('/user/logout',
            {
                user: { id: appStore.userId }
            }
        ).then( () => {
            window.localStorage.clear("cookie");
            this.clearSession();
        })
    }

    /**
     * 로그인 세션이 쿠키에 남아있을때 세션정보를 재 로드한다.
     */
    @action.bound
    async fetchSessionInfo() {
        try {
            let store = this;
            store.isUpdatingSession = true;
            const response = await axios.get('/user/session-touch', {withCredentials:true}).finally(r=>{
                store.isUpdatingSession = false;
            });
            if(!response.data){
                this.clearSession()
            }else{
                let {id, name, email, type, loginId, avatar} = response.data;
                this.updateSession({
                    id,
                    name : name,
                    email: email,
                    type : type,
                    loginId : loginId,
                    avatar
                })
            }

        } catch (e) {
            this.clearSession();
        } finally {
            this.isSessionChecked = true;
        }
    }

    openModal = ()=> {
        this.isModalOpen = !this.isModalOpen;

        if(this.isModalOpen === true)
            costStore.refs.get('user.name').focus()
    }

    @observable
    openLightboxOnSlideSink(number) {
        this.onLightBoxSink = !this.onLightBoxSink;
        this.onLightBoxSlideSink = number;
    };

    openLightboxOnSlideDress(number) {
        this.onLightBoxDress = !this.onLightBoxDress;
        this.onLightBoxSlideDress = number;
    };


}

const appStore = new AppStore();
export default appStore;
