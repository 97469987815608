import React from "react";
import Styles from "./TopPlateDoubleColorChip.module.scss";

class TopPlateDoubleColorChip extends React.Component{
    render() {
        let {colorName, children} = this.props;
        return(
            <div className={Styles.BaseColor}>
                {children}
                <div className={Styles.ColorName}>{colorName}</div>
            </div>
        );
    }
}

export default TopPlateDoubleColorChip;