import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, InputGroup} from 'react-bootstrap';
import {observer} from 'mobx-react';
import CommonHelper from '../../helper/CommonHelper';
import {NumericFormat} from 'react-number-format';
import {FormStore, Validation} from '../validation';

// [참고] react-number-format 문서 : https://www.npmjs.com/package/react-number-format

@observer
class NumberInput extends Component {

    constructor(props) {
        super(props);
        let {store, name, validations} = this.props;
        store.validationMap.set(name, validations);
    }

    render() {
        let {store, name, validOnBlur, onBlur, decimalScale = 0, format, /*maxLength = 11,*/ readOnly = false, fixedDecimalScale = false, thousandSeparator} = this.props;
        const hasTried = store.hasTried.has(name);
        const errors = store.getErrorMessages(name);
        let value = store.valueMap.get(name);
        return (
            <>
                <div className={`d-flex flex-column flex-grow-1`}>
                    <InputGroup hasValidation>
                        <NumericFormat fixedDecimalScale={fixedDecimalScale}
                                      thousandSeparator={thousandSeparator}
                                      readOnly={readOnly}
                                      customInput={Form.Control}
                                      value={value || ''}
                                      isInvalid={hasTried && (errors && errors.length > 0)}
                                      getInputRef={ref => store.refs.set(name, ref)}
                                      onValueChange={(values) => {
                                          const {value} = values;
                                          store.valueMap.set(name, value);
                                      }}
                                      onBlur={e => {
                                          if (validOnBlur)
                                              store.hasTried.set(name, true);
                                          onBlur && onBlur(e);
                                      }}
                                      format={format}
                                      decimalScale={decimalScale}
                                      {...CommonHelper.omit(this.props, 'store', 'name', 'numberTitle', 'validations', 'validOnBlur', 'onBlur')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors && errors.length > 0) && errors[0]}
                        </Form.Control.Feedback>
                    </InputGroup>
                </div>
            </>
        );
    }


}

NumberInput.propTypes = {
    store            : PropTypes.instanceOf(FormStore).isRequired,
    name             : PropTypes.string.isRequired,
    thousandSeparator: PropTypes.bool,
    validations      : PropTypes.arrayOf(PropTypes.instanceOf(Validation)),
    validOnBlur      : PropTypes.bool,
    format           : PropTypes.string,
    decimalScale     : PropTypes.number,
};

NumberInput.defaultProps = {
    thousandSeparator: false,
};

export default NumberInput;
