import React, {Component} from 'react';
import {observer} from "mobx-react";
import Styles from "./RandingView.module.scss";
import InnerWrap from "../component/layout/InnerWrap";
import Logo from "../component/layout/Logo";
import appStore from "../store/AppStore";
import moment from "moment/moment";
import HeadContentArrow from "../component/animate/HeadContentArrow";
import ScrollAnimation from "react-animate-on-scroll";
import Section from "../component/layout/Section";
import Footer from "../component/layout/Footer";
import {ArrowDown2, Building3, Building4, Buildings, Buildings2, I24Support, Judge, Shop} from "iconsax-react";
import EbackFreeCall from "../component/layout/EbackFreeCall";
import EbakModalButtonOtherStyle from "../component/button/EbakModalButtonOtherStyle";
import {navigate} from "../helper/HistoryHelper";


@observer
class RandingView extends Component {

    componentDidMount() {
        window.scrollTo({ left: 0, top: 0, behavior: "instant" });

        var referenceNode = document.getElementsByTagName('script')[0];
        var newNode = document.createElement('script');
        newNode.setAttribute('type', 'text/javascript');
        newNode.setAttribute('id', 'talk_banner');
        newNode.src = 'https://ssl.pstatic.net/static.talk/bizmember/script/2022083100/js/banner/talk_banner.js';
        referenceNode.parentNode.insertBefore(newNode, referenceNode);
    }

    /*constructor(props) {
        super(props);
    }*/

    render() {

        return (
            <div>
                <nav className={Styles.Header}>
                    <InnerWrap>
                        <nav className={Styles.TopMenu}>
                            <Logo
                                logoName={'이백인테리어'}
                                logoNameColor={'#263238'}
                                doorColor={'#263238'}
                                iconNavigateUrl={'/main'}
                                characterNavigateUrl={'/main'}
                            />
                            <div className={`${Styles.Navigation} ${appStore.isOpen ? Styles.Active : ''}  `}>
                                <ul>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => {
                                                 navigate('/company')
                                                 window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                             }}>회사소개
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => {
                                                 navigate('/sink')
                                                 window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                             }}>자재선택
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => appStore.openModal()}>실측예약
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => {
                                                 navigate('/search/photo')
                                                 window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                             }}>현장사진</div>
                                    </li>
                                </ul>
                                <EbackFreeCall />
                            </div>
                        </nav>
                    </InnerWrap>
                </nav>
                <main>
                    <div className={Styles.HeadContent}>
                        <div className={Styles.ContentAnimation}
                             style={{backgroundImage: `url("/asset/img/randing/randing_mainvisual.svg?${moment().format()}")`}}/>

                        <div className={Styles.Content}
                             style={{backgroundImage: 'url("/asset/img/randing/randing_mainvisual.jpg")'}}>
                            <div>
                                <h1 className={Styles.Title}>
                                    <p>씽크대 붙박이장</p>
                                    <p>이백인테리어에서</p><p> 쉽고 간편하게</p>
                                </h1>
                                <div className={Styles.LinkButton}>
                                    <div className="talk_banner_div" data-id="132357"></div>
                                    <EbakModalButtonOtherStyle label={'현장상담 신청하기'}>

                                    </EbakModalButtonOtherStyle>
                                </div>
                            </div>
                            <HeadContentArrow/>
                        </div>
                    </div>
                    <div className={Styles.MainContent}>
                        <div className={Styles.Band}>
                            <InnerWrap>
                                <ScrollAnimation animateIn='fadeInUp'
                                                 animateOnce={true}
                                                 duration={0.5}

                                >
                                    <h3 className={Styles.Text}>
                                        <p>씽크대, 붙박이장, 각종 수납장을 한곳에서 상담하고 시공하세요.</p>
                                        <p>이제껏 경험하지 못한 쉽고 편리한 <span>팩토리메이드 시공, </span>이백인테리어와 함께라면 당신의 일상이 새로워질 거에요.</p>
                                    </h3>
                                </ScrollAnimation>
                            </InnerWrap>
                        </div>
                        <Section>
                            <InnerWrap>

                                <div className={Styles.Content}>

                                    <div className={Styles.ContentTitle}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}

                                        >
                                            <div className={Styles.TextTitle}>
                                                <h3>씽크대</h3>
                                                <h1>
                                                    <p>우리집 스타일,</p>
                                                    <p>주방에서부터 시작하는</p>
                                                    <p>드라마틱한 변화</p>
                                                </h1>
                                            </div>
                                        </ScrollAnimation>

                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={Styles.FreeImages}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}

                                                             offset={400}
                                            >
                                                <div className={Styles.LongImage}>
                                                    <img src={"/asset/img/randing/randing_section01_02.jpg"} alt={''} />
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}

                                            >
                                                <div className={Styles.SubLongImage}>
                                                    <img src={"/asset/img/randing/randing_section01_01.jpg"} alt={''} />
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}

                                                         offset={0}
                                        >
                                            <div className={Styles.FreeText}>
                                                <h4 className={Styles.SubText}>
                                                    <p>이백인테리어와 함께 해보세요.</p>
                                                    <p>상담, 실측, 3D랜더링은 기본,</p>
                                                    <p>취향에 맞춘 자재선정까지</p>
                                                    <p>한 번에 할 수 있어요.</p>
                                                </h4>
                                            </div>
                                        </ScrollAnimation>
                                    </div>

                                </div>
                            </InnerWrap>
                        </Section>
                        <Section isBg={true}>
                            <InnerWrap className={Styles.InnerWrap}>

                                <div className={Styles.Content}>
                                    <ScrollAnimation animateIn='fadeInUp'
                                                     animateOnce={true}
                                                     duration={0.5}
                                    >

                                        <div className={Styles.ContentTitle}>
                                            <div className={Styles.TextTitle}>
                                                <h3>붙박이장</h3>
                                                <h1>
                                                    <p>누구나 꿈꾸는</p>
                                                    <p>예쁜 드레스룸,</p>
                                                    <p>모두의 공간에 딱!</p>
                                                    <p>이런 디자인, 보셨나요?</p>
                                                </h1>
                                            </div>
                                            <div className={Styles.ImageTitle}>
                                                <img src={"/asset/img/randing/randing_section02_01.jpg"} alt={''} />
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                    <div className={Styles.Article}>

                                        <div className={Styles.JustifyLayout}>

                                            <div className={`${Styles.ImageAndText}`}>
                                                <div className={Styles.Image}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/randing_section02_02.jpg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >

                                                        <h5 className={Styles.SubTitle}>
                                                            <Building3 size='22' color='#0B63F3' variant={'Outline'}/>
                                                            <span>안방이 작은 빌라 시공</span>
                                                        </h5>
                                                        <h4 className={Styles.SubDesc}>
                                                            <p>시공 전, 동선을 고려하여</p>
                                                            <p>사용에 불편함이 없도록 합니다.</p>
                                                        </h4>
                                                        <p className={Styles.RegularText}>
                                                            정확한 실측을 위해 반드시 현장 점검을 꼼꼼하게
                                                            진행하고 책자와 샘플 도어를 직접 보고 재질을
                                                            선택하여 충분히 결정하신 후에 공사를 진행하는
                                                            것을 원칙으로 합니다.
                                                        </p>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>

                                            <div className={`${Styles.ImageAndText} ${Styles.Reverse}`}>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <h5 className={Styles.SubTitle}>
                                                            <Judge size='22' color='#0B63F3' variant={'Outline'}/>
                                                            <span>시공 후 1년간 A/S</span>
                                                        </h5>
                                                        <h4 className={Styles.SubDesc}>
                                                            <p>이백인테리어에서 시공하신 모두에게</p>
                                                            <p>제품의 하자로 인한 A/S기간 1년</p>
                                                        </h4>
                                                        <div className={Styles.RegularText}>
                                                            <p>브랜드에서 시공받으시려고 하는 가장 큰 이유가 A/S 때문이라는 말씀을 많이 듣습니다.</p>
                                                            <p>이백인테리어에서 시공하신 모든 분들께 제품의 하자로 인한 A/S기간을 1년 보장합니다.</p>
                                                            <p>또한 사용상의 고장으로 인한 A/S도 유상수리가 가능합니다.</p>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Image}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/randing_section02_03.jpg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                            </div>

                                            <div className={`${Styles.ImageAndText}`}>
                                                <div className={Styles.Image}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/randing_section02_04.jpg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <h5 className={Styles.SubTitle}>
                                                            <I24Support size='22' color='#0B63F3' variant={'Outline'}/>
                                                            <span>24시간 응대 가능</span>
                                                        </h5>
                                                        <h4 className={Styles.SubDesc}>
                                                            <p>이백인테리어는 고객님의 시간을</p>
                                                            <p>금이라고 생각합니다.</p>
                                                        </h4>
                                                        <div className={Styles.RegularText}>
                                                            <p>너무 늦은 시간, 너무 이른 시간이라고 망설이지 마세요.</p>
                                                            <p>24시간 실측 예약이 가능한 카카오톡 채널과 네이버톡톡이 있습니다.</p>
                                                            <p>상담직원이 자고 있어도 채팅상담은 쉬지 않습니다.</p>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>

                                <div className={Styles.Content}>

                                    <div className={Styles.ContentTitle}>
                                        <div className={Styles.TextTitle}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}

                                            >
                                                <h3>현장사진</h3>
                                                <h1>
                                                    <p>여러 현장의 시공사진을</p>
                                                    <p>클릭 한번에 확인 해보세요.</p>
                                                </h1>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className={Styles.Article}>

                                        <div className={Styles.JustifyLayout}>
                                            <div className={`${Styles.ImageAndText} ${Styles.Reverse}`}>

                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Text}>

                                                        <h1 className={Styles.SubDesc}>
                                                            <p>퀄리티는 <span>높게,</span></p>
                                                            <p>구매가는 <span>낮게,</span></p>
                                                            <p>부담감은 <span>적게.</span></p>
                                                        </h1>
                                                        <div className={Styles.SmallText}
                                                             style={{backgroundColor: '#fff'}}>
                                                            <p>앉은 자리에서 여러 현장의 시공사진을 확인하고
                                                                우리집에 꼭 맞는 디자인을 골라보세요.</p>
                                                            <p>퀄리티, 예산까지 모두 맞춰드립니다.</p>
                                                        </div>
                                                    </div>
                                                </ScrollAnimation>

                                                <div className={Styles.ImageBackground}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/randing_section03.jpg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </InnerWrap>
                        </Section>

                        <Section isBg={true}>
                            <InnerWrap>
                                <div className={Styles.Content}>

                                    <div className={Styles.ContentTitle}>
                                        <div className={Styles.TextTitle}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}

                                            >
                                                <h3>시공 순서</h3>
                                                <h1>
                                                    <p>공사의 첫 걸음</p>
                                                    <p>시공 순서를 알고나면 공사가 보입니다.</p>
                                                </h1>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className={Styles.Article}>

                                        <div className={`${Styles.JustifyLayout} ${Styles.DoubleSlice}`}>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <p>현장 실측과 상담</p>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>실측 날짜를 협의하여, 고객님이 계신 곳에서
                                                                    편하게 상담 받으시고 자유롭게 선택하세요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img
                                                            src={"/asset/img/randing/iconsax/ReceiptEdit-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <p>견적서 확인 후 계약 진행</p>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>실측한 것을 바탕으로 견적서를 작성하여 보내드려요.</p>
                                                                <p>충분히 확인하고 계약을 진행하세요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/iconsax/Judge-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>시공받기</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>시공 날짜에 정해진 시공기사가 방문하여 시공을 진행해요.</p>
                                                                <p>일정에 변동이 필요할 땐 미리 확인해서 알려주세요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/iconsax/Edit-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>검수 후 마무리</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>시공 현장에 하자가 없는지 꼼꼼하게 확인 하셨나요?</p>
                                                                <p>검수가 끝나면 현장을 깔끔하게 정리하고 마무리 합니다.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.ContentTitle}>
                                        <div className={Styles.TextTitle}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}

                                            >
                                                <h3>상담</h3>
                                                <h1>
                                                    <p>인테리어,</p>
                                                    <p>모두가 쉽게 접근 할 수 있도록</p>
                                                </h1>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className={Styles.Article}>

                                        <div className={Styles.JustifyLayout}>

                                            <div className={Styles.TextAndImageAndText}>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <h4 className={Styles.RightAlign}>
                                                            <p>이해하기 쉬운 용어</p>
                                                            <p> 설명이 필요없는</p>
                                                            <p>3D 랜더링</p>
                                                        </h4>
                                                    </ScrollAnimation>
                                                </div>

                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >

                                                    <div className={Styles.RollingImages}>

                                                        <img
                                                            src={"/asset/img/randing/randing_section04_baseimage.png"} alt={''}/>
                                                        <div className={Styles.ChangeImage}>
                                                            <img src={"/asset/img/randing/randing_section04_01.jpg"} alt={''} />
                                                            <img src={"/asset/img/randing/randing_section04_02.jpg"} alt={''} />
                                                            <img src={"/asset/img/randing/randing_section04_03.jpg"} alt={''} />
                                                        </div>

                                                    </div>
                                                </ScrollAnimation>

                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <h4 className={Styles.LeftAlign}>
                                                            <p>쉽고 빠른 구매경험,</p>
                                                            <p>자재선택에 도움을 주는</p>
                                                            <p>샘플북까지</p>
                                                        </h4>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={Styles.BandText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h2 className={Styles.CenterAlign}>
                                                        <p>복잡한 인테리어 공사는 이제 그만,</p>
                                                        <p>이백인테리어에서 쉽고 빠르게 나만의 공간을 꾸며보세요.</p>
                                                    </h2>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <ScrollAnimation animateIn='fadeIn'
                                         animateOnce={true}
                                         duration={1}

                        >
                            <div className={`${Styles.Band} ${Styles.UseBG}`}
                                 style={{
                                     backgroundImage: 'url("/asset/img/randing/randing_band_image.jpg")',
                                     width: '100%',
                                     opacity: '1',
                                     color: '#FFFFFF'
                                 }}>
                                <div className={Styles.InnerWrap}>
                                    <h3>
                                        <p style={{textShadow: '0, 0, 1rem,1rem, #000'}}>꼭 필요한 부분만 시공</p>
                                    </h3>
                                </div>
                            </div>
                        </ScrollAnimation>

                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.ContentTitle}>
                                        <div className={Styles.TextTitle}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}

                                            >
                                                <h1>
                                                    <p>이백인테리어에서,</p>
                                                    <p>나에게 딱 맞게</p>
                                                </h1>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className={Styles.Article}>

                                        <div className={Styles.FreeLayOut}>
                                            <div className={Styles.CenterImage}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <img src={"/asset/img/randing/randing_section05_01.jpg"} alt={''} />
                                                </ScrollAnimation>
                                            </div>
                                            <div className={Styles.DescText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Title}>
                                                        <h2>
                                                            우리집 인테리어,<span> 실측부터 시공까지 한 번에</span>
                                                        </h2>
                                                    </div>
                                                    <div className={Styles.RegularText}>
                                                        <p>인테리어 회사의 장점은 소비자 상담이 친절하고 골치 아픈 결정을 대신 해 준다는 것.</p>
                                                        <p>공장의 장점은 저렴하고 시공이 확실하며 ‘전문가답다’는 점이죠.</p>
                                                        <p>이백인테리어는 이 두 가지 장점을 모두 갖고 있는 ‘팩토리메이드 브랜드’ 입니다.</p>
                                                        <p>싱크대, 붙박이장은 이백인테리어에서 원스톱 서비스(one-stop service)로 간편하게
                                                            해결하세요.</p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                            <div className={Styles.CenterRowImage}>
                                                <div className={Styles.Slice}>

                                                    <div className={Styles.LeftImage}>
                                                        <ScrollAnimation animateIn='fadeInUp'
                                                                         animateOnce={true}
                                                                         duration={0.5}

                                                        >
                                                            <img src={"/asset/img/randing/randing_section05_02.jpg"} alt={''} />
                                                        </ScrollAnimation>
                                                    </div>


                                                    <div className={Styles.RightImageAndText}>

                                                        <div className={Styles.Image}>
                                                            <ScrollAnimation animateIn='fadeInUp'
                                                                             animateOnce={true}
                                                                             duration={0.5}

                                                            >
                                                                <img
                                                                    src={"/asset/img/randing/randing_section05_03.jpg"} alt={''} />
                                                            </ScrollAnimation>
                                                        </div>
                                                        <ScrollAnimation animateIn='fadeInUp'
                                                                         animateOnce={true}
                                                                         duration={0.5}

                                                        >
                                                            <h4>
                                                                내가 받은 견적서 금액이 적절한지,
                                                                낸 만큼 퀄리티와 서비스를 보장받고
                                                                있는지 확인해보세요.
                                                            </h4>
                                                        </ScrollAnimation>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className={Styles.DescText} style={{marginBottom: '12.5rem'}}>
                                                <div className={Styles.Title}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <h2>
                                                            <p>씽크대,붙박이장</p>
                                                            <p><span>문의부터 상담과 시공까지</span></p>
                                                            <p><span>모든 공사를 간편하게</span></p>
                                                        </h2>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>

                                            <div className={`${Styles.CenterImage} ${Styles.FullImage}`}
                                                 style={{marginBottom: '4rem'}}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <img src={"/asset/img/randing/randing_section05_04.jpg"} alt={''} />
                                                </ScrollAnimation>
                                            </div>
                                            <div className={Styles.DescText}>
                                                <div className={Styles.Title}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <h2>
                                                            씽크대, 붙박이장 <span>시공 후 관리에 대한 생각</span>
                                                        </h2>
                                                    </ScrollAnimation>
                                                </div>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.RegularText}>
                                                        <p>
                                                            시공 받고 난 후, 하자가 있다면 빠르게 A/S가 가능한지, 고장이 났을 때에도 즉시 응대 가능한지,
                                                            유명 브랜드의 제품을 선택하지 않았기 때문에 불안했던 공사 후 관리에 대한 부분을
                                                            이백인테리어에서 깔끔하게 지워드려요.
                                                        </p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </InnerWrap>
                        </Section>
                        <Section isBg={true}>
                            <InnerWrap>
                                <div className={Styles.Content}>

                                    <div className={Styles.ContentTitle}>
                                        <div className={Styles.TextTitle}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}

                                            >
                                                <h3>알아두면 도움되는 시공 상식</h3>
                                                <h1>
                                                    <p>알아두면 좋은 시공 정보,</p>
                                                    <p>한번 읽어보세요.</p>
                                                </h1>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className={Styles.Article}>

                                        <div className={`${Styles.JustifyLayout} ${Styles.DoubleSlice}`}>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/iconsax/Call-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>전화 상담</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>실측 전 예상금액을 먼저 알고 싶나요?</p>
                                                                <p>공사를 하고 싶은 가구의 길이를 재서 표기한 이미지를 공유해주시면 현장 방문 전 가상 견적을
                                                                    계산해 드려요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>

                                            </div>
                                            <div className={`${Styles.IconAndText}`}>
                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/iconsax/BoxSearch-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>3D 랜더링 제공</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>나의 생활패턴에 맞는 디자인으로
                                                                    공사 전에 3D랜더링으로
                                                                    시공후 바뀐 공간을
                                                                    미리 확인 할 수 있어요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/iconsax/CardPos-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>카드 무이자 혜택</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>카드사별 무이자 혜택을 이백에서도
                                                                    받으실 수 있어요.</p>
                                                                <p>결제 전 혜택 안내 공지를 확인하세요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>
                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <img src={"/asset/img/randing/iconsax/Edit-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>이백 플랜 서비스</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>대량 공사를 담당하고 계신가요?</p>
                                                                <p>이백 플랜 서비스로 건축하시는 건물의 모든
                                                                    씽크대, 붙박이장, 수납장 시공을
                                                                    차별화된 견적가로 안내 드립니다.</p>
                                                                <p>담당자님의 공사 일정에 맞춰 계획하고
                                                                    품질이 보장된 가구를 차질없이 시공하세요.</p>
                                                                <p>이백 플랜은 시공 후 하자 관리까지 보장해드립니다.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.Article}>
                                        <div className={Styles.IconGroup}>

                                            <div className={Styles.IconText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Icon}>
                                                        <Buildings2 size="96" color="#FFFFFF" variant={"Outline"}/>
                                                    </div>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>아파트</h3>
                                                </ScrollAnimation>
                                            </div>

                                            <div className={Styles.IconText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Icon}>
                                                        <Buildings size="96" color="#FFFFFF" variant={"Outline"}/>
                                                    </div>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>타운하우스</h3>
                                                </ScrollAnimation>
                                            </div>

                                            <div className={Styles.IconText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Icon}>
                                                        <Building4 size="96" color="#FFFFFF" variant={"Outline"}/>
                                                    </div>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>빌라</h3>
                                                </ScrollAnimation>
                                            </div>

                                            <div className={Styles.IconText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Icon}>
                                                        <Building3 size="96" color="#FFFFFF" variant={"Outline"}/>
                                                    </div>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>주택</h3>
                                                </ScrollAnimation>
                                            </div>

                                            <div className={Styles.IconText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Icon}>
                                                        <Shop size="96" color="#FFFFFF" variant={"Outline"}/>
                                                    </div>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>상가</h3>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                        <ScrollAnimation animateIn='fadeInDown'
                                                         animateOnce={true}
                                                         duration={0.5}

                                        >
                                            <div className={Styles.AnimeArrow}>
                                                <ArrowDown2 size='300' variant={'Outline'}/>
                                            </div>
                                        </ScrollAnimation>

                                        <div className={Styles.TextBlock}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                            >
                                                <h1>사업도 이백인테리어와 함께</h1>
                                                <h2>
                                                    <p>건축을 계획하고 계시나요?</p>
                                                    <p>건축물 안의 모든 맞춤 가구, 상담부터 시공까지</p>
                                                    <p>이제 이백인테리어와 함께 하세요.</p>
                                                </h2>
                                            </ScrollAnimation>
                                        </div>

                                        <div className={Styles.LinkGroup}>

                                            <div className={Styles.LinkDesc}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>결제하기</h3>
                                                    <div className={Styles.RegularText}>
                                                        <p>공사진행을 원하시는 경우, 공사 대금의 50%를 공사 전 착수금으로 입금 완료하고 공사가 완료된 후 나머지를
                                                            결제하는 것이 원칙입니다.</p>
                                                        <p>착수금 미입금시 공사가 진행되지 않을 수 있습니다.</p>
                                                    </div>

                                                </ScrollAnimation>
                                            </div>

                                            <div className={Styles.LinkDesc}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>공사진행</h3>
                                                    <div className={Styles.RegularText}>
                                                        <p>공사 일정에 맞춰 최소 1주일 전까지는 자재와 디자인의 결정이 완료되어야 합니다.</p>
                                                        <p>공장에서 재단이 시작되면 자재변경이 어렵고 재단된 만큼의 자재를 폐기해야 하는 경우가 발생합니다.</p>
                                                    </div>
                                                    
                                                </ScrollAnimation>
                                            </div>

                                            <div className={Styles.LinkDesc}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>견적문의</h3>
                                                    <div className={Styles.RegularText}>
                                                        <p>아파트, 빌라, 타운하우스, 상가 등 대량시공 견적을 문의해보세요.</p>
                                                        <p>합리적인 견적으로 건축을 계획하시는 모든
                                                            사장님들께 도움 드리고자 합니다.</p>
                                                        <p>캐드도면, PDF파일 첨부 가능합니다.</p>
                                                    </div>
                                                    
                                                </ScrollAnimation>
                                            </div>

                                            <div className={Styles.LinkDesc}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3>A/S</h3>
                                                    <div className={Styles.RegularText}>
                                                        <p>이백인테리어에서 시공 받으신 모든 분들께는 공사 후 1년간 제품 하자로 인한 무상 A/S가 가능합니다.</p>
                                                        <p>또한, 사용상의 파손으로 인한 유상 A/S도 이백인테리어에서 깔끔하게 진행됩니다.</p>
                                                    </div>
                                                    
                                                </ScrollAnimation>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                    </div>

                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>


        );
    }
}

RandingView.propTypes = {};

export default RandingView;