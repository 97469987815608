import React from "react";
import Styles from "./Footer.module.scss";
import {observer} from "mobx-react";
import appStore from "../../store/AppStore";
import {navigate} from "../../helper/HistoryHelper";

@observer
class Footer extends React.Component {

    render() {
        let{companyInfo}= this.props;
        return (
            <div className={Styles.Footer}>
                <div className={Styles.FooterWarp}>
                    <div className={Styles.SiteGroup}>
                        <div>
                            <h5>서비스</h5>
                            <ul>
                                {/*<li>공지사항</li>
                                <li>자주묻는 질문</li>*/}
                                <li onClick={() => {
                                    navigate('/privacypolicy')
                                    window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                }}>개인정보보호</li>
                                <li onClick={() => {
                                    navigate('/termsofuse')
                                    window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                }}>이용약관</li>
                            </ul>
                        </div>

                        <div>
                            <h5>회사</h5>
                            <ul>
                                <li onClick={() => {
                                    navigate('/company')
                                    window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                }}>회사소개</li>
                                <li onClick={() =>{appStore.openModal()}}>이백플랜서비스</li>
                                <li onClick={()=>{window.open('https://www.instagram.com/200_interior')}}>인스타그램</li>
                                <li onClick={()=>window.open('https://blog.naver.com/insaengd')}>블로그</li>
                            </ul>
                        </div>

                        <div>
                            <h5>문의</h5>
                            <ul>
                                <li onClick={() =>{appStore.openModal()}}>대량시공</li>
                                <li onClick={() =>{appStore.openModal()}}>광고문의</li>
                                <li onClick={() =>{appStore.openModal()}}>사업문의</li>
                            </ul>
                        </div>

                        <div>
                            <h5>고객센터</h5>
                            <ul>
                                <li onClick={()=>{document.location.href='tel:010-4298-2102'}}>전화</li>
                                <li onClick={()=>{window.location='mailto:200interior@gmail.com'}}>이메일</li>
                                <li onClick={() =>{appStore.openModal()}}>1:1문의</li>
                            </ul>
                        </div>

                    </div>
                    <div>
                        <div className={Styles.CompanyName}><h5>이백 인테리어</h5></div>
                        <div className={Styles.CompanyDesc}>
                            <p style={{cursor:"pointer"}} onClick={()=>window.open('http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1435000660')}>사업자 등록번호 : 143-50-00660 | 대표 : 유정은</p>
                            <p>통신판매업신고번호 : 2022-용인동백-0219</p>
                            <p>주소 : 경기도 광주시 새말길 279 힐링하우스 1층 상가 (CU 윗층)</p>
                            <p>고객센터 : 031-716-2228 | FAX : 031-716-2229</p>
                            <p>개인정보보호 책임자 : 유정은</p>
                            <p>Copyright © 2022 이백인테리어 All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;