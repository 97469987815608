import React, {Component} from 'react';
import Styles from './DressView.module.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import {navigate} from "../helper/HistoryHelper";
import PantingColorChip from "../component/colorchip/PantingColorChip";
import PetColorChip from "../component/colorchip/PetColorChip";
import Footer from "../component/layout/Footer";
import InnerWrap from "../component/layout/InnerWrap";
import Logo from "../component/layout/Logo";
import HeadContentArrow from "../component/animate/HeadContentArrow";
import AppStore from "../store/AppStore";
import Section from "../component/layout/Section";
import DressModule from "../component/colorchip/DressModule";
import {observer} from "mobx-react";
import CollapseSample from "../component/transitions/CollapseSample";
import EbakButton from "../component/button/EbakButton";
import CubeScene from "../component/animate/CubeScene";
import EbackNotice from "../component/layout/EbackNotice";
import EbackFreeCall from "../component/layout/EbackFreeCall";
import appStore from "../store/AppStore";

@observer
class DressView extends Component {

    componentDidMount() {
        appStore.isModalOpen = false;
        appStore.isOpen = false;
    }

    constructor(props) {
        super(props);
        this.state = {
            isStart: false
        };
    }

    render() {

        return (
            <div>
                <nav className={Styles.Header}>
                    <InnerWrap>
                        <nav className={Styles.TopMenu}>
                            <Logo
                                logoName={'자재선택'}
                                logoNameColor={'#263238'}
                                doorColor={'#263238'}
                                iconNavigateUrl={'/company'}
                                characterNavigateUrl={'/company'}
                            />
                            <div className={`${Styles.Navigation} ${AppStore.isOpen ? Styles.Active : ''}`}>
                                <ul>
                                    <li>
                                        <div className={Styles.LinkMenu} onClick={() => {
                                            navigate('/sink')
                                            window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                        }}>
                                            씽크대
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu} onClick={() => {
                                            navigate('/dress')
                                            window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                        }}>
                                            붙박이장
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu} onClick={() => appStore.openModal()}>
                                            실측예약
                                        </div>
                                    </li>
                                    <li><div className={Styles.LinkMenu} onClick={()=>navigate('/search/photo')}>현장사진</div></li>
                                </ul>
                                <EbackFreeCall />
                            </div>
                        </nav>
                    </InnerWrap>
                </nav>
                <main>
                    <div className={Styles.HeadContent}
                         style={{backgroundImage: 'url("/asset/img/randing/dress_mainvisual.jpg")'}}>

                        <InnerWrap>
                            <div className={Styles.Content}>
                                <div className={Styles.TextArea}>
                                    <h1 className={Styles.Title}>
                                        <p>평생 사랑하게 될</p>
                                        <p>세상에서 가장 아름다운 공간</p>
                                        <p>BUILT-IN MY LIFE</p>
                                    </h1>
                                </div>
                                <HeadContentArrow/>
                            </div>
                        </InnerWrap>
                    </div>
                    <div className={Styles.MainContent}>
                        <Section isBg={false}>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.ContentTitle}>
                                        <h1>
                                            <p>인테리어의 특별함을 열망하는 사람들이 모여,</p>
                                            <p>역사에 남을만한 변화를 만듭니다.</p>
                                        </h1>
                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={`${Styles.JustifyLayout} ${Styles.DoubleSlice}`}>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>

                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>친환경 E0보드 사용</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>기본 중의 기본, 친환경 자재 사용으로 아이들이 있어도 걱정없이 시공해요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>


                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>자유롭게 변화가능한 내부 구성</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>공장직영만 가능한 최대장점.</p>
                                                                <p>옷봉, 선반, 서랍장 모두 쓰임에 맞게 자유롭게 선택하세요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>

                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>철거 부터 시공까지 단 하루</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>급한 일정이 있으시다면?</p>
                                                                <p>오전철거, 오후시공으로 부담을 줄여보세요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>


                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}                                                       
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>다양한 자재 시공 가능</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>시스템 행거부터 깔끔한 여닫이 도어, 슬라이딩 문까지 다양한 자재를 활용하여 공간에 딱 맞는 가구를 제작 해보세요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section isBg={true}>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.ContentTitle}>
                                        <h1>
                                            <p><span style={{color:'rgba(0,150,136, 0.4)'}}>좋은 자재로</span></p>
                                            <p><span style={{color:'#009688'}}>좋은 가구를</span> 만듭니다.</p>
                                        </h1>
                                    </div>
                                    <div className={Styles.Article}>

                                        <div className={Styles.FreeLayOut}>

                                            <div className={Styles.DescText}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                                 
                                                >
                                                    <div className={Styles.Title}>
                                                        <h2>
                                                            <p>혹시 맞춤가구 고를 때, 목재 등급도 살펴보시나요?</p>
                                                        </h2>
                                                    </div>
                                                    <div className={Styles.RegularText}>
                                                        <p>집은 우리 가족 모두의 행복을 담는 공간입니다.</p>
                                                        <p>우리 가족이 생활하는 공간에 맞춰서 제작하는 가구라면 자재까지 꼼꼼하게 따져봐야겠죠.</p>
                                                        <p>그렇다면 친환경 자재의 기준은 무엇일까요?</p>
                                                        <p>목재의 환경 등급은 포름알데히드 방출량을 측정하여 매겨집니다.</p>
                                                        <p>포름알데히드는 인체에 대해 독성이 강하여 두통 및 아토피를 유발하고 새집증후군의 주범이 되는 물질입니다.</p>
                                                        <p>걱정스러운 것은 아직도 원자재 가격 때문에 E1자재를 사용하는 공장이 많다는 사실입니다.</p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                            <div className={Styles.leftImage}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <img src={"/asset/img/randing/dress_section01_01.jpg"} alt={''} />
                                                </ScrollAnimation>
                                            </div>

                                            <div className={`${Styles.DescText} ${Styles.MarginBottomZero}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <div className={Styles.Title}>
                                                        <h2>
                                                            <p>이제 안심하고 이백인테리어에 맡겨주세요.</p>
                                                        </h2>
                                                    </div>
                                                    <div className={Styles.RegularText}>
                                                        <p>이미 포름알데하이드 수치가 뉴스거리가 되기 이전부터 이백인테리어의 가구 제작에 사용하는 모든 목재는 포름알데하이드 0.5mg 미만 친환경 E0 보드만을 사용하고 있었습니다.</p>
                                                        <p>모두의 건강을 위해, 이백인테리어는 자부심을 갖고 제품을 생산합니다.</p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.ContentTitle} ${Styles.ContentTitleAlignCenter}`}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}
                                        >
                                            <h4>내가 필요한 구성으로 자유롭게 선택하세요</h4>
                                            <h1 style={{textAlign: 'center', color: '#263238'}}>
                                                <p>다양한 구성의 모듈을</p>
                                                <p>원하는 대로 선택하여 만들어보세요.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={Styles.TextAndImagesColumn}>

                                            <div className={Styles.Text}>
                                                <h3 className={Styles.Title}>
                                                    <p>12가지 구성 수백가지 조합</p>
                                                    <p>어떤 용도든 완벽하게 조합가능 합니다.</p>
                                                </h3>
                                                <div className={Styles.RegularText}>
                                                    <p>항상 애매하게 공간이 없어서 쌓아 두셨던 물건들 하나쯤은 있으시죠?</p>
                                                    <p>이젠 우리집에 딱 맞는 용도로 제작하여 언제나 방치되는 물건없이 깔끔하게 정리해보세요.</p>
                                                </div>
                                            </div>

                                            <div className={Styles.TextBlock}>
                                                <h3>붙박이장 모듈 리스트</h3>
                                            </div>

                                            <div className={Styles.ImageGroupModule}>
                                                <div className={Styles.Images}>
                                                    <DressModule moduleName={'Module 01'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type001.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 02'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type002.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 03'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type003.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 04'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type004.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 05'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type005.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 06'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type006.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 07'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type007.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 08'}>
                                                        <img className={Styles.ModuleImageDobule}
                                                             src={"/asset/img/randing/dress_material/dress_module_type008.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 09'}>
                                                        <img className={Styles.ModuleImageSingle}
                                                             src={"/asset/img/randing/dress_material/dress_module_type009.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 10'}>
                                                        <img className={Styles.ModuleImageSingle}
                                                             src={"/asset/img/randing/dress_material/dress_module_type010.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 11'}>
                                                        <img className={Styles.ModuleImageSingle}
                                                             src={"/asset/img/randing/dress_material/dress_module_type011.jpg"} alt={''} />
                                                    </DressModule>
                                                    <DressModule moduleName={'Module 12'}>
                                                        <img className={Styles.ModuleImageSingle}
                                                             src={"/asset/img/randing/dress_material/dress_module_type012.jpg"} alt={''} />
                                                    </DressModule>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Styles.FreeImageAndTextGroup}>
                                            <div className={Styles.TextBlock}>
                                                <h3>많이 찾는 모듈조합</h3>
                                            </div>
                                            <div className={Styles.FreeImageAndText}>

                                                <div className={Styles.Image}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                    <img src={"/asset/img/randing/dress_material/dress_module_union_001.png"} alt={""} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                    <h5>3 BASIC MODULE</h5>
                                                    <div className={Styles.HLine}></div>
                                                    <h4 className={Styles.ModuleNum}>Module 02 + 01 + 08</h4>
                                                    <div className={Styles.RegularText}>
                                                        <p>이불장</p>
                                                        <p>2단장</p>
                                                        <p>선반 분리형 옷장</p>
                                                    </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.FreeImageAndText}`}>
                                                <div className={Styles.Image}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                    <img src={"/asset/img/randing/dress_material/dress_module_union_002.png"} alt={""} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                    <h5>3.5 BASIC MODULE</h5>
                                                    <div className={Styles.HLine}></div>
                                                    <h4 className={Styles.ModuleNum}>Module 02 + 01 + 06 + 12</h4>
                                                    <div className={Styles.RegularText}>
                                                        <p>이불장</p>
                                                        <p>2단장</p>
                                                        <p>1단 서랍 긴옷장</p>
                                                        <p>선반 장</p>
                                                    </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.FreeImageAndText} ${Styles.MarginBottomZero}`}>
                                                <div className={Styles.Image}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                    <img src={"/asset/img/randing/dress_material/dress_module_union_003.png"} alt={""} />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                    <h5>4 BASIC MODULE</h5>
                                                    <div className={Styles.HLine}></div>
                                                    <h4 className={Styles.ModuleNum}>Module 02 + 01 + 06 + 08</h4>
                                                    <div className={Styles.RegularText}>
                                                        <p>이불장</p>
                                                        <p>2단장</p>
                                                        <p>1단 서랍 긴옷장</p>
                                                        <p>선반 분리형 긴옷장</p>
                                                    </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.ContentTitle} ${Styles.ContentTitleAlignCenter}`}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}
                                        >
                                            <h4>다양하고 아름다운 색상</h4>
                                            <h1 style={{textAlign: 'center', color: '#263238'}}>
                                                <p>아름답고 다채로운 색상</p>
                                                <p>무드를 바꾸는 가장 쉬운 방법.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={Styles.TextAndImagesColumn}>
                                            <div className={Styles.Text}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}

                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>합리적인 가격으로 다양한 컬러 제작</p>
                                                        <p>: 스탠다드 페트보드</p>
                                                    </h3>
                                                </ScrollAnimation>

                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <p>합리적인 가격으로 수납장을 제작하려고 하신다면, 가장 심플한 디자인에 다양한 컬러를 선택할 수 있는 스텐다드 페트 상품을 선택해보세요.</p>
                                                        <p>컬러를 싱크대, 신발장 도어 등과 맞춰서 제작이 가능해서 룸 전체의 컬러 밸런스를 맞추기에 좋습니다.</p>
                                                        <p>합리적인 가격에 제작이 가능하다는 점이 가장 큰 장점입니다.</p>

                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.ColorSample}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <CollapseSample buttonLabel={'샘플컬러 확인하기'}>
                                                            <div>
                                                                <div className={Styles.TextBlock}>
                                                                    <h3>페트 도어 샘플</h3>
                                                                </div>
                                                                <div className={Styles.ImageGroupPet}>
                                                                    <div className={Styles.Images}>
                                                                        <PetColorChip colorName={'HS 트루펄화이트'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material001.jpg"} alt={''} />
                                                                            <img className={Styles.OverImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material001_over.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 퍼펙트화이트(유광)'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material002.jpg"} alt={''} />
                                                                            <img className={Styles.OverImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material002_over.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 퍼펙트화이트(무광)'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material003.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 크림화이트(유광)'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material004.jpg"} alt={''} />
                                                                            <img className={Styles.OverImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material004_over.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 크림화이트(무광)'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material005.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 샌드그레이'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material006.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 코튼블루'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material007.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 로지핑크'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material008.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 시에라오크'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material009.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 시에라월넛'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material010.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 밀키화이트'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material011.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 그레이지'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material012.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 세이지그린'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material013.jpg"} alt={''} />
                                                                            <img className={Styles.OverImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material013_over.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 스톤그레이'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material014.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 도브화이트'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material015.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 라이트그레이'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material016.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 스모키올리브'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material017.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 바살트그레이'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material018.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                        <PetColorChip colorName={'HS 인디고(무광)'}>
                                                                            <img className={Styles.BaseImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_pet_material019.jpg"} alt={''} />
                                                                        </PetColorChip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CollapseSample>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={Styles.Text}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}

                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>고급스러운 컬러와 견고한 옷장을 원하신다면</p>
                                                        <p>: 프라임 도장도어</p>
                                                    </h3>
                                                </ScrollAnimation>
                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <p>도장이란 가구용 도료를 이용하여 가구에 여러번 수작업으로 덧칠하여 처리하는 기법입니다.</p>
                                                        <p>도장 처리는 목재의 수명을 길게 유지하며 외관상으로도 다양한 색상과 디자인을 표현 할 수 있기 때문에 하이 퀄리티 제품으로 꾸준히 사랑받고 있습니다.</p>
                                                        <p>도어로 사용되는 기본 목재의 디자인도 다양하고 원하는 컬러를 모두 표현할 수 있기 때문에 특별한 드레스룸을 꾸미고자 하는 분들이라면 한번쯤 눈여겨 볼 만 한 제품입니다.</p>
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.ColorSample}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <CollapseSample buttonLabel={'샘플컬러 확인하기'}>
                                                            <div>
                                                                <div className={Styles.TextBlock}>
                                                                    <h3>도장 도어 컬러 샘플</h3>
                                                                </div>
                                                                <div className={Styles.ImageGroupPanting}>
                                                                    <div className={Styles.Images}>
                                                                        <PantingColorChip colorName={'화이트'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material001.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>

                                                                        <PantingColorChip colorName={'미백색'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material002.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'아이보리'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material003.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'누드 화이트'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material004.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'누드 올리브'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material005.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'오션 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material006.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material007.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'스톰 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material008.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'민트 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material009.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'민트'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material010.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'카키'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material011.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'스카이 블루'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material012.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'핑크'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material013.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'다크 옐로우'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material014.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'딥 그린'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material015.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'틸 그린'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material016.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'블루'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material017.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'인디고 블루'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material018.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'다크 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material019.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'진 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material020.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'다크 브라운'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material021.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'블랙'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material022.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CollapseSample>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.Text} ${Styles.MarginBottomZero}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}

                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>고급스러운 컬러와 견고한 옷장을 원하신다면</p>
                                                        <p>: 프라임 도장도어</p>
                                                    </h3>
                                                </ScrollAnimation>
                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <p>도장이란 가구용 도료를 이용하여 가구에 여러번 수작업으로 덧칠하여 처리하는 기법입니다.</p>
                                                        <p>도장 처리는 목재의 수명을 길게 유지하며 외관상으로도 다양한 색상과 디자인을 표현 할 수 있기 때문에 하이 퀄리티 제품으로 꾸준히 사랑받고 있습니다.</p>
                                                        <p>도어로 사용되는 기본 목재의 디자인도 다양하고 원하는 컬러를 모두 표현할 수 있기 때문에 특별한 드레스룸을 꾸미고자 하는 분들이라면 한번쯤 눈여겨 볼 만 한 제품입니다.</p>
                                                    </ScrollAnimation>
                                                </div>
                                                <div style={{marginTop:'2rem'}}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <EbakButton label={'스토리북 바로가기'} navigateUrl={'/dress'} />
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.ContentTitle} ${Styles.ContentTitleAlignCenter}`}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}
                                        >
                                            <h4>상담문의 전 측정 하시면 편리해요</h4>
                                            <h1 style={{textAlign: 'center', color: '#263238'}}>
                                                <p>시공을 원하는 부분의</p>
                                                <p>치수를 측정하여 상담하시면 더욱 편리합니다.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={Styles.TextAndImagesColumn}>

                                            <div className={`${Styles.Text} ${Styles.MarginBottomSmall}`}>
                                                <h3 className={Styles.Title}>
                                                    <p>시공 공간 사이즈 측정 방법</p>
                                                </h3>
                                            </div>

                                            <div className={Styles.Image}>
                                                <CubeScene />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <EbackNotice />
                            </InnerWrap>
                        </Section>
                    </div>
                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>


        )
            ;
    }
}

DressView.propTypes = {};

export default DressView;