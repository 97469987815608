import {action, computed, makeObservable, observable} from 'mobx';
import {MENU_GROUP_RE} from '../constant/AppConst';
import Cookies from 'js-cookie';
import * as moment from 'moment';
import axios from 'axios';
import {navigate} from "../helper/HistoryHelper";
import {toast} from "react-toastify";
import {costStore} from "../view/CostCalculationView";


class LoginDialogStore {

    constructor() {
        makeObservable(this);
    }

    @observable
    show = false;

    open(){
        this.show = true;
    }
    close(){
        this.show = false;
    }

}

export const loginDialogStore = new LoginDialogStore();

