import React, {Component} from 'react';
import {Redirect, Route, Router, Switch, BrowserRouter} from 'react-router-dom';
import {observer} from 'mobx-react';
import {BROWSER_HISTORY, HISTORY} from './helper/HistoryHelper';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import RandingView from "./view/RandingView";
import CompanyView from "./view/CompanyView";
import SinkView from "./view/SinkView";
import DressView from "./view/DressView";
import CostCalculationView from "./view/CostCalculationView";
import PhotoView from "./view/PhotoView";
import PrivacyPolicyView from "./view/PrivacyPolicyView";
import TermsOfUseView from "./view/TermsOfUseView";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {LoginDialog} from "./component/dialog/LoginDialog";
import {loginDialogStore} from "./store/LoginDialogStore";
import appStore from "./store/AppStore";
import DetailPhotoView from "./view/DetailPhotoView";


@observer
class App extends Component {


    render() {
        return (
            <GoogleOAuthProvider clientId="276964584825-e1abfn8n7q4cjh8684av08vdb4dbjj71.apps.googleusercontent.com">
                <div>
                    <CostCalculationView />
                    {
                        <Router history={HISTORY}>
                            <Switch>
                                <Route path="/main" component={RandingView}/>
                                <Route path="/company" component={CompanyView}/>
                                <Route path="/sink" component={SinkView}/>
                                <Route path="/dress" component={DressView}/>
                                <Route path="/search/photo" component={PhotoView}/>
                                <Route path="/privacypolicy" component={PrivacyPolicyView}/>
                                <Route path="/termsofuse" component={TermsOfUseView}/>
                                <Route path="/photo/detail/:id" component={DetailPhotoView}/>
                                <Redirect to="/main" exact="/"/>
                            </Switch>

                        </Router>

                    }
                    <ToastContainer/>
                    <LoginDialog store={loginDialogStore}/>
                </div>
            </GoogleOAuthProvider>
        );
    }
}

export default App;
