import React from "react";
import Styles from "./DressModule.module.scss";

class DressModule extends React.Component{
    render() {
        let {moduleName, children} = this.props;
        return(
            <div className={Styles.BaseModule}>
                {children}
                <div className={Styles.ModuleName}>{moduleName}</div>
            </div>
        );
    }
}

export default DressModule;