import React, {Component} from 'react';
import Styles from './SinkView.module.scss';
import {Box} from 'iconsax-react';
import ScrollAnimation from 'react-animate-on-scroll';
import {navigate} from "../helper/HistoryHelper";
import Viewport from "../component/animate/Viewport";
import PantingColorChip from "../component/colorchip/PantingColorChip";
import PetColorChip from "../component/colorchip/PetColorChip";
import TopPlateColorChip from "../component/colorchip/TopPlateColorChip";
import TopPlateDoubleColorChip from "../component/colorchip/TopPlateDoubleColorChip";
import Footer from "../component/layout/Footer";
import InnerWrap from "../component/layout/InnerWrap";
import Logo from "../component/layout/Logo";
import HeadContentArrow from "../component/animate/HeadContentArrow";
import AppStore from "../store/AppStore";
import Section from "../component/layout/Section";
import {observer} from "mobx-react";
import CollapseSample from "../component/transitions/CollapseSample";
import EbackNotice from "../component/layout/EbackNotice";
import EbackFreeCall from "../component/layout/EbackFreeCall";
import appStore from "../store/AppStore";


@observer
class SinkView extends Component {

    componentDidMount() {
        appStore.isModalOpen = false;
        appStore.isOpen = false;
    }

    constructor(props) {
        super(props);
        this.state = {
            isStart: false
        };
    }

    render() {

        return (
            <div>
                <nav ref={this.ref} className={Styles.Header}>
                    <InnerWrap>
                        <nav className={Styles.TopMenu}>
                            <Logo
                                logoName={'자재선택'}
                                logoNameColor={'#263238'}
                                doorColor={'#263238'}
                                iconNavigateUrl={'/company'}
                                characterNavigateUrl={'/company'}
                            />
                            <div className={`${Styles.Navigation} ${AppStore.isOpen ? Styles.Active : ''}`}>
                                <ul>
                                    <li>
                                        <div className={Styles.LinkMenu} onClick={() => {
                                            navigate('/sink')
                                            window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                        }}>
                                            씽크대
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu} onClick={() => {
                                            navigate('/dress')
                                            window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                        }}>
                                            붙박이장
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu} onClick={() => appStore.openModal()}>
                                            실측예약
                                        </div>
                                    </li>
                                    <li><div className={Styles.LinkMenu} onClick={()=>navigate('/search/photo')}>현장사진</div></li>
                                </ul>
                                <EbackFreeCall />
                            </div>
                        </nav>
                    </InnerWrap>
                </nav>
                <main>
                    <div className={Styles.HeadContent}
                         style={{backgroundImage: 'url("/asset/img/randing/sink_mainvisual.jpg")'}}>
                        <InnerWrap>
                            <div className={Styles.Content}>
                                <div className={Styles.TextArea}>
                                    <h1 className={Styles.Title}>
                                        <p>씽크대, 그 이상의</p>
                                        <p>가치를 만들고 있습니다.</p>
                                    </h1>
                                </div>
                                <HeadContentArrow/>
                            </div>
                        </InnerWrap>
                    </div>
                    <div className={Styles.MainContent}>
                        <Section isBg={false}>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.ContentTitle}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}

                                        >
                                            <h1>
                                                <p>갖고 싶은 주방,</p>
                                                <p style={{paddingBottom: 0}}>이백인테리어가 만들어 드릴게요.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>

                                        <div className={`${Styles.JustifyLayout} ${Styles.DoubleSlice}`}>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>

                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>친환경 E0보드 사용</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>기본 중의 기본, 친환경 자재 사용으로 아이들이 있어도 걱정없이 시공해요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>


                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>100% 자사 공장 운영</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>공장직영 제품으로 상담부터 시공까지 한방에 해결 해요.</p>
                                                                <p> A/S까지 확실하게 책임져요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>

                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>빌트인 시스템장</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>요즘 스타일 참 어렵죠?</p>
                                                                <p>이백인테리어는 다양한 주방 기기들 모두 호환 가능한 맞춤형 디자인이 가능해요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>

                                                <div className={Styles.Icon}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <img src={"/asset/img/randing/iconsax/RulerPen-Outline.svg"} alt={''} />
                                                    </ScrollAnimation>
                                                </div>


                                                <div className={Styles.Text}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <div>
                                                            <h3 className={Styles.SubTitle}>
                                                                <span>다양한 자재 시공 가능</span>
                                                            </h3>

                                                            <div className={Styles.RegularText}>
                                                                <p>시스템 행거부터 깔끔한 여닫이 도어, 슬라이딩 문까지 다양한 자재를 활용하여 공간에 딱 맞는
                                                                    가구를
                                                                    제작해요.</p>
                                                            </div>
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section isBg={true} BgColor={'#263238'}>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.ContentTitle} ${Styles.ContentTitleAlignCenter}`}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}
                                        >
                                            <h1 style={{textAlign: 'center', color: '#FFFFFF'}}>
                                                <p>이백인테리어는 오늘도</p>
                                                <p style={{paddingBottom: 0}}>이렇게 씽크대를 만들고 있습니다.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={Styles.StepGroup}>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <ScrollAnimation animateIn='fadeInUp'
                                                                             animateOnce={true}
                                                                             duration={0.5}
                                                            >
                                                                <p>STEP 01</p>
                                                            </ScrollAnimation>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <ScrollAnimation animateIn='fadeInUp'
                                                                             animateOnce={true}
                                                                             duration={0.5}
                                                            >
                                                                <h4>씽크대 시공 문의</h4>
                                                            </ScrollAnimation>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div className={`${Styles.Shape}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <p>STEP 02</p>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <h4>
                                                                <span className={Styles.CompanyColor}>
                                                                    이백인테리어
                                                                </span>
                                                                &nbsp; 현장 방문 및 실측</h4>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div
                                                                className={`${Styles.Shape} ${Styles.ShapeColorCompany}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <p>STEP 03</p>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <h4>
                                                                <span className={Styles.CompanyColor}>
                                                                    이백인테리어
                                                                </span>
                                                                &nbsp; 견적서 작성</h4>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div
                                                                className={`${Styles.Shape} ${Styles.ShapeColorCompany}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <p>STEP 04</p>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <h4>
                                                                <span className={Styles.CustomerColor}>
                                                                    소비자
                                                                </span>
                                                                &nbsp; 견적서 확인 및 계약 확정</h4>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div
                                                                className={`${Styles.Shape} ${Styles.ShapeColorCustomer}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <p>STEP 05</p>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <h4>
                                                                <span className={Styles.CompanyColor}>
                                                                    이백인테리어
                                                                </span>
                                                                &nbsp; 3D 랜더링 이미지 전달</h4>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div
                                                                className={`${Styles.Shape} ${Styles.ShapeColorCompany}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <p>STEP 06</p>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <h4>
                                                                <span className={Styles.CustomerColor}>
                                                                    소비자
                                                                </span>
                                                                &nbsp; 도면 확정 및 공사 날짜 결정</h4>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div
                                                                className={`${Styles.Shape} ${Styles.ShapeColorCustomer}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <p>STEP 07</p>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <h4>
                                                                <span className={Styles.CompanyColor}>
                                                                    이백인테리어
                                                                </span>
                                                                &nbsp; 공장 제작 시작</h4>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div
                                                                className={`${Styles.Shape} ${Styles.ShapeColorCompany}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <p>STEP 08</p>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <h4>
                                                                <span className={Styles.CompanyColor}>
                                                                    이백인테리어
                                                                </span>
                                                                &nbsp; 시공</h4>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div
                                                                className={`${Styles.Shape} ${Styles.ShapeColorCompany}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                            <div className={Styles.Step}>
                                                <div className={Styles.ShapeAndText}>
                                                    <div className={Styles.Text}>
                                                        <div className={Styles.StepText}>
                                                            <ScrollAnimation animateIn='fadeInUp'
                                                                             animateOnce={true}
                                                                             duration={0.5}
                                                            >
                                                                <p>STEP 09</p>
                                                            </ScrollAnimation>
                                                        </div>
                                                        <div className={Styles.DescText}>
                                                            <ScrollAnimation animateIn='fadeInUp'
                                                                             animateOnce={true}
                                                                             duration={0.5}
                                                            >
                                                                <h4>시공 완료</h4>
                                                            </ScrollAnimation>
                                                        </div>
                                                        <Viewport animationClass={Styles.StartAnimate}>
                                                            <div className={`${Styles.Shape}`}/>
                                                        </Viewport>
                                                    </div>
                                                    <div className={Styles.Padding}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className=
                                             {`${Styles.ContentTitle} ${Styles.ContentTitleAlignCenter}`}
                                         style=
                                             {{marginBottom: 0}}
                                    >
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}
                                        >
                                            <Box className={Styles.ShakeAnimation} size={'6rem'} color={'#263238'}
                                                 variant={'outline'}/>
                                            <h1 style={{textAlign: 'center', color: '#263238'}}>
                                                <p style={{paddingBottom: 0, paddingTop: '2rem'}}>자재를 선택해 보세요.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>

                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.ContentTitle} ${Styles.ContentTitleAlignCenter}`}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}
                                        >
                                            <h4>가장 많은 사랑을 받고있는</h4>
                                            <h1 style={{textAlign: 'center', color: '#263238'}}>
                                                <p>페트 도어와 인조대리석 시공으로</p>
                                                <p>가성비 있는 주방을 만들어요.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={Styles.TextAndImagesColumn}>

                                            <div className={Styles.Text}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}

                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>건강을 생각하는 친환경 E0 보드,</p>
                                                        <p>: 스탠다드 페트보드</p>
                                                    </h3>
                                                </ScrollAnimation>

                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <p>사랑하는 우리 아이들과 애완동물을 위해 위험한 포름알데히드
                                                            본드를 사용하지 않는
                                                            <span style={{color: '#F49C0A', fontWeight: 'bold'}}>
                                                        'E0 등급의 목재'
                                                    </span>로 만들어 졌습니다.</p>
                                                        <p>이백인테리어는 언제나 소비자의 건강과 안전을 고민합니다.</p>
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.ColorSample}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                    <CollapseSample buttonLabel={'샘플컬러 확인하기'}>
                                                        <div>
                                                            <div className={Styles.TextBlock}>
                                                                <h3>페트 도어 샘플</h3>
                                                            </div>
                                                            <div className={Styles.ImageGroupPet}>
                                                                <div className={Styles.Images}>
                                                                    <PetColorChip colorName={'HS 트루펄화이트'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material001.jpg"} alt={''} />
                                                                        <img className={Styles.OverImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material001_over.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 퍼펙트화이트(유광)'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material002.jpg"} alt={''} />
                                                                        <img className={Styles.OverImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material002_over.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 퍼펙트화이트(무광)'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material003.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 크림화이트(유광)'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material004.jpg"} alt={''} />
                                                                        <img className={Styles.OverImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material004_over.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 크림화이트(무광)'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material005.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 샌드그레이'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material006.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 코튼블루'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material007.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 로지핑크'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material008.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 시에라오크'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material009.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 시에라월넛'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material010.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 밀키화이트'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material011.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 그레이지'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material012.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 세이지그린'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material013.jpg"} alt={''} />
                                                                        <img className={Styles.OverImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material013_over.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 스톤그레이'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material014.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 도브화이트'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material015.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 라이트그레이'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material016.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 스모키올리브'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material017.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 바살트그레이'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material018.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                    <PetColorChip colorName={'HS 인디고(무광)'}>
                                                                        <img className={Styles.BaseImage}
                                                                             src={"/asset/img/randing/sink_material/sink_pet_material019.jpg"} alt={''} />
                                                                    </PetColorChip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CollapseSample>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>


                                            <div className={`${Styles.Text} ${Styles.MarginBottomZero}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}

                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>가성비로 완성하는 주방 디자인</p>
                                                        <p>: 인조 대리석 상판</p>
                                                    </h3>
                                                </ScrollAnimation>
                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <p>모든 단면이 균일한 무늬로 조금 닳더라도 무늬가 유지되어 유지보수 없이 오래도록 사용가능하고
                                                            김치국물 같은 오염이나 스크래치로 인한 손상도 처음같은 모습으로 복구가 가능하며,
                                                            물에도 강하기 때문에 청소와 관리가 매우 쉽습니다.</p>
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.ColorSample}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                    <CollapseSample buttonLabel={'샘플컬러 확인하기'}>
                                                        <div>
                                                            <div className={Styles.TextBlock}>
                                                                <h3>인조 대리석 상판 샘플</h3>
                                                            </div>
                                                            <div className={Styles.ImageGroupTopPlate}>
                                                                <div className={Styles.Images}>
                                                                    <TopPlateColorChip colorName={'소프트화이트 G185'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material001.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'페르나화이트 P001'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material002.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'캔디화이트 G235'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material003.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'아틱 그라나이트 G034'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material004.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'화이트스텔라 G501'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material005.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'스와니 G193'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material006.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'소프트그레이지 G186'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material007.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'스노우 G004'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material008.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'크리스탈베이지 G101'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material009.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'베이지아일랜드 G109'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material010.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'스톤파우더 G005'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material011.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'스모그 G002'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material012.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'소프트그레이 G184'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material013.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'윈터스텔라 G502'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material014.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'밀키웨이 G007'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material015.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'콜그레이 G078'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material016.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'나이트스텔라 G503'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material017.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'그레이오닉스 G103'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material018.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'챠밍블랙 G053'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material019.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'블랙버드 G047'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material020.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'페르나블랙 P004'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material021.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'스노우콘크리트 G556'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material022.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'어반콘크리트 G554'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material023.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'스틸콘크리트 G555'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material024.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'그라빌라스노우 GM01'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material025.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'그라빌라크림 GM02'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material026.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'다이아몬드화이트 S034'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material027.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'알파인화이트 S028'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material028.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'아틱화이트 S006'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material029.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'누가크림 S201'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material030.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'그레이 S005'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material031.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                    <TopPlateColorChip colorName={'블랙 S022'}>
                                                                        <img className={Styles.ArtificialImage}
                                                                             src={"/asset/img/randing/sink_material/sink_artificialmarble_material032.jpg"}
                                                                             alt={""}/>
                                                                    </TopPlateColorChip>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CollapseSample>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.ContentTitle} ${Styles.ContentTitleAlignCenter}`}>
                                        <ScrollAnimation animateIn='fadeInUp'
                                                         animateOnce={true}
                                                         duration={0.5}
                                        >
                                            <h4 style={{}}>고급 자재로 품격있는 주방을 환성하고 싶다면,</h4>
                                            <h1 style={{textAlign: 'center', color: '#263238'}}>
                                                <p>도장(Painting) 도어와 함께</p>
                                                <p>세라믹&칸스톤 상판으로</p>
                                                <p>프리미엄 주방을 만들어 보세요.</p>
                                            </h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className={Styles.Article}>
                                        <div className={Styles.TextAndImages}>

                                            <div className={Styles.Text}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}

                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>페트와 도장 도어의 차이를</p>
                                                        <p>눈으로 확인해 보세요.</p>
                                                    </h3>
                                                </ScrollAnimation>
                                                <div className={Styles.VSImage}>

                                                    <div className={Styles.PetImage}>
                                                        <ScrollAnimation animateIn='fadeInUp'
                                                                         animateOnce={true}
                                                                         duration={0.5}

                                                        >
                                                            <img className={Styles.PetBaseImage}
                                                                 src={"/asset/img/randing/sink_vs_pet_001.png"} alt={""}/>
                                                            <img className={Styles.PetOverImage}
                                                                 src={"/asset/img/randing/sink_vs_pet_002.png"} alt={""}/>
                                                            <p className={Styles.VSName}>패트(PET) 도어</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                    <h1 className={Styles.VsText}>VS</h1>
                                                    <div className={Styles.PaintingImage}>
                                                        <ScrollAnimation animateIn='fadeInUp'
                                                                         animateOnce={true}
                                                                         duration={0.5}

                                                        >
                                                            <img src={"/asset/img/randing/sink_vs_panting_001.png"}
                                                                 alt={""}/>
                                                            <p className={Styles.VSName}>도장(PAINTING) 도어</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                                <div className={`${Styles.RegularText} ${Styles.MarginBottomNormal}`}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <p>페트(PET)도어는 싱크대도어의 커팅된(엣지) 부분에 마감을 하기 때문에
                                                            공정 과정상 이음새 부분 작은 틈이 생겨 물이나 이물질이 들어갈수 있어,
                                                            관리에 따라 벌어짐 등의 문제가 생길 수 있지만</p>
                                                        <p>도장(PANTING)도어는 원장위에 직접 7번 정도 덧칠을 하기 때문에 이음새가 없이 깔끔하며,
                                                            불량률이 적고, 관리가 매우 쉽다는 장점이 있습니다.</p>
                                                    </ScrollAnimation>
                                                </div>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}

                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>고급스러운 컬러와 견고한 도어</p>
                                                        <p>: 프라임 도장도어</p>
                                                    </h3>
                                                </ScrollAnimation>

                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <p>도장이란 가구용 도료를 이용하여 가구에 여러번 수작업으로 덧칠하여 처리하는 기법입니다.</p>
                                                        <p>도장 처리는 목재의 수명을 길게 유지하며 외관상으로도 다양한 색상과 디자인을 표현 할 수 있기 때문에 하이 퀄리티 제품으로 꾸준히 사랑받고 있습니다.</p>
                                                        <p>도어로 사용되는 기본 목재의 디자인도 다양하고 원하는 컬러를 모두 표현할 수 있기 때문에 특별한 드레스룸을 꾸미고자 하는 분들이라면 한번쯤 눈여겨 볼 만 한 제품입니다.</p>

                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.ColorSample}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <CollapseSample buttonLabel={'샘플컬러 확인하기'}>
                                                            <div>
                                                                <div className={Styles.TextBlock}>
                                                                    <h3>도장 도어 컬러 샘플</h3>
                                                                </div>
                                                                <div className={Styles.ImageGroupPanting}>
                                                                    <div className={Styles.Images}>
                                                                        <PantingColorChip colorName={'화이트'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material001.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>

                                                                        <PantingColorChip colorName={'미백색'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material002.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'아이보리'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material003.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'누드 화이트'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material004.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'누드 올리브'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material005.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'오션 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material006.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material007.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'스톰 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material008.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'민트 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material009.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'민트'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material010.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'카키'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material011.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'스카이 블루'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material012.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'핑크'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material013.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'다크 옐로우'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material014.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'딥 그린'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material015.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'틸 그린'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material016.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'블루'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material017.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'인디고 블루'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material018.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'다크 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material019.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'진 그레이'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material020.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'다크 브라운'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material021.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                        <PantingColorChip colorName={'블랙'}>
                                                                            <img className={Styles.PantingImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_panting_material022.jpg"}
                                                                                 alt={""}/>
                                                                        </PantingColorChip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CollapseSample>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Styles.TextAndImagesColumn}>

                                            <div className={Styles.Text}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>변하지 않는 아름다움,</p>
                                                        <p>: 세라믹 상판</p>
                                                    </h3>
                                                </ScrollAnimation>
                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <p>이탈리아, 스페인의 대자연 속에서 직접 추출한 친환경 천연 광물을 재료로 사용한 Porcelain
                                                            stoneware(포세린 스톤웨어) 입니다.</p>
                                                        <p>천연 광물을 고온/고압으로 압축하여 제작되어 다이아몬드처럼 경도가 뛰어나 스크레치가 잘 나지 않고 생활 얼룩에
                                                            강하며, 뜨거운 열도 견딜만큼 견고합니다.</p>
                                                        <p>그렇기에 곰팡이나 세균또한 침투하기 어려우며 이러한 장점때문에 수명이 길어 오랫동안 사용할 수
                                                            있습니다.</p>
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.ColorSample}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <CollapseSample buttonLabel={'샘플컬러 확인하기'}>
                                                            <div>
                                                                <div className={Styles.TextBlock}>
                                                                    <h3>세라믹 상판 샘플</h3>
                                                                </div>
                                                                <div className={Styles.ImageGroupDoubleTopPlate}>
                                                                    <div className={Styles.Images}>

                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'스타투아리오 그라지오 (POLISHED & MATT)'}>
                                                                            <img className={Styles.CeramicImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_ceramic_material001.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>

                                                                        <TopPlateDoubleColorChip colorName={'스타투아리오 오로 (MATT)'}>
                                                                            <img className={Styles.CeramicImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_ceramic_material002.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>

                                                                        <TopPlateDoubleColorChip colorName={'그레 비안코 (MATT)'}>
                                                                            <img className={Styles.CeramicImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_ceramic_material003.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>

                                                                        <TopPlateDoubleColorChip colorName={'카세로 비안코 (MATT)'}>
                                                                            <img className={Styles.CeramicImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_ceramic_material004.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>

                                                                        <TopPlateDoubleColorChip colorName={'포르토 로톤도 (MATT)'}>
                                                                            <img className={Styles.CeramicImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_ceramic_material005.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>

                                                                        <TopPlateDoubleColorChip colorName={'카프레라 (MATT)'}>
                                                                            <img className={Styles.CeramicImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_ceramic_material006.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>

                                                                        <TopPlateDoubleColorChip colorName={'나이트 스톰 (POLISHED)'}>
                                                                            <img className={Styles.CeramicImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_ceramic_material007.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CollapseSample>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={Styles.TextAndImagesColumn}>

                                            <div className={`${Styles.Text} ${Styles.MarginBottomZero}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >
                                                    <h3 className={Styles.Title}>
                                                        <p>프리미엄 주방, 공간의 가치를 높이는 선택</p>
                                                        <p>: 칸스톤(KHAN STONE)</p>
                                                    </h3>
                                                </ScrollAnimation>
                                                <div className={Styles.RegularText}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}
                                                    >
                                                        <p>천연소재에 첨단기술을 더한 친환경 신소재로서 광택 및 표면강도,</p>
                                                        <p>내오염성이 우수할 뿐만 아니라 기존 석재 및 인조석과 비교할 때</p>
                                                        <p>월등한 품질과 아름다움을 가집니다.</p>
                                                    </ScrollAnimation>
                                                </div>
                                                <div className={Styles.ColorSample}>
                                                    <ScrollAnimation animateIn='fadeInUp'
                                                                     animateOnce={true}
                                                                     duration={0.5}

                                                    >
                                                        <CollapseSample buttonLabel={'샘플컬러 확인하기'}>
                                                            <div>
                                                                <div className={Styles.TextBlock}>
                                                                    <h3>칸스톤 상판 샘플</h3>
                                                                </div>
                                                                <div className={Styles.ImageGroupDoubleTopPlate}>
                                                                    <div className={Styles.Images}>

                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'트랜퀄리티 RU613'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material001.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'윈터워킹 RU657'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material002.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'스트라토 CA701'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material003.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'코로나 WT755'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material004.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'아틀렉틱 그레이 GV781'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material005.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'모나코 SU758'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material006.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'올리바루아 WT756'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material007.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'비안코캔버스 BA201'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material008.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'스피치오 화이트 CT402'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material009.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'앰브로셜 누보 MO626'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material010.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'아잔타 RM504'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material011.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'콩코드 MO624'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material012.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'마띠에르 BT354'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material013.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'옵디시안 블랙 CT401'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material014.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'인디안펄 RU658'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material015.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'밀라디 RU612'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material016.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'비스코티 RU656'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material017.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'소피아 RU608'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material018.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'아라마스 RU607'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material019.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                        <TopPlateDoubleColorChip
                                                                            colorName={'블랙스노우 RU656'}>
                                                                            <img className={Styles.KhanstoneImage}
                                                                                 src={"/asset/img/randing/sink_material/sink_khanstone_material020.jpg"}
                                                                                 alt={""}/>
                                                                        </TopPlateDoubleColorChip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CollapseSample>
                                                    </ScrollAnimation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <EbackNotice />
                            </InnerWrap>
                        </Section>
                    </div>
                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>


        )
            ;
    }
}

SinkView.propTypes = {};

export default SinkView;