import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'airbnb-browser-shims';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import appStore from './store/AppStore';
import {HISTORY} from './helper/HistoryHelper';
import {MENU_GROUP_RE} from './constant/AppConst';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-image-lightbox/style.css';

import {configure} from 'mobx';
configure({enforceActions:'never'})

// 주소표시줄에 직접입력시 처리
appStore.currentPath = HISTORY.location.pathname;

let matched = appStore.currentPath.match(MENU_GROUP_RE);
if (matched && matched.length > 1) {
    appStore.currentMenuGroup = matched[1];
}

appStore.fetchSessionInfo().then(r => {
    console.log('세션 확인됨.');
});

ReactDOM.render(
    <App/>,
    document.getElementById('root'),
);

reportWebVitals();
