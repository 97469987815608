import React from "react";
import Styles from "./Section.module.scss";
import {observer} from "mobx-react";

@observer
class Section extends React.Component {

    render() {
        let {isBg, children, BgColor} = this.props;
        return (
            <div className={`${Styles.Section} ${isBg ? Styles.UseBG : ''}`} style={{backgroundColor:BgColor}}>
                    {children}
            </div>
        );
    }
}

export default Section;