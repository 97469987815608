import React, {Component} from 'react';
import Styles from './CompanyView.module.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import {navigate} from "../helper/HistoryHelper";
import CountUp from 'react-countup';
import handleViewport from "react-in-viewport";
import InnerWrap from "../component/layout/InnerWrap";
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";
import Logo from "../component/layout/Logo";
import Section from "../component/layout/Section";
import HeadContentArrow from "../component/animate/HeadContentArrow";
import Footer from "../component/layout/Footer";
import Lottie from "lottie-react";
import FreeConsulting from "../component/lottie/free_consolting.json";
import FreeInspection from "../component/lottie/free_inspection.json";
import EbakModalButton from "../component/button/EbakModalButton";
import EbackFreeCall from "../component/layout/EbackFreeCall";
import appStore from "../store/AppStore";


@observer
class CountNumber extends Component {

    render() {
        return (
            <span>
                {
                    this.props.isStart &&
                    <CountUp
                        start={999}
                        end={5000}
                        duration={2.5}
                        startOnMount={false}
                        onStart={() => {
                            console.log("OK")
                        }}
                    />
                }
            </span>
        )
    }

}

class CountNumberDown extends Component {

    render() {
        return (
            <span>
                {
                    this.props.isStartDown &&
                    <CountUp
                        start={99999}
                        end={0}
                        duration={5}
                        startOnMount={false}
                        onStart={() => {
                            console.log("OK")
                        }}
                    />
                }
            </span>
        )
    }

}

const ViewCountUp = handleViewport(CountNumber)
const ViewCountDown = handleViewport(CountNumberDown)

@observer
class CompanyView extends Component {

    componentDidMount() {
        window.scrollTo({ left: 0, top: 0, behavior: "instant" });
        appStore.isModalOpen = false;
        appStore.isOpen = false;
    }

    constructor(props) {
        super(props);
        this.state = {
            isStart: false,
            isStartDown: false
        };
    }

    render() {

        return (
            <div>
                <nav className={Styles.Header}>
                    <InnerWrap>
                        <nav className={Styles.TopMenu}>
                            <Logo
                                logoName={'이백인테리어'}
                                logoNameColor={'#F7FAFC'}
                                doorColor={'#F7FAFC'}
                                iconNavigateUrl={'/company'}
                                characterNavigateUrl={'/company'}
                            />
                            <div className={`${Styles.Navigation} ${AppStore.isOpen ? Styles.Active : ''}`}>
                                <ul>
                                    <li><div className={Styles.LinkMenu} onClick={() => {
                                        navigate('/company')
                                        window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                    }}>회사소개</div></li>
                                    <li><div className={Styles.LinkMenu} onClick={() => {
                                        navigate('/sink')
                                        window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                    }}>자재선택</div></li>
                                    <li><div className={Styles.LinkMenu} onClick={() => appStore.openModal()}>실측예약</div></li>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => {
                                                 navigate('/search/photo')
                                                 window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                     }}>현장사진</div>
                                    </li>
                                </ul>
                                <EbackFreeCall />
                            </div>
                        </nav>
                    </InnerWrap>
                </nav>
                <main>

                    <div className={Styles.HeadContent} style={{backgroundColor: '#263238'}}>

                        <InnerWrap>

                            <div className={Styles.Content}>
                                <div className={Styles.TextArea}>
                                    <h1 className={Styles.Title}>
                                        <p>씽크대, 붙박이장이 필요한</p>
                                        <p>모든 순간,</p><p>이백인테리어</p>
                                    </h1>
                                    <div className={Styles.RegularText}>
                                        <p>간편한 현장상담부터 시공까지,</p>
                                        <p>이백인테리어 단 하나로 완벽하게 경험해보세요.</p>
                                    </div>
                                    <EbakModalButton
                                        label={'현장상담 신청하기'}
                                        navigateUrl={'/#/cost'}
                                    >
                                        <img className={Styles.EbackButtonIcon}
                                             src={"asset/img/randing/iconsax/Call-Outline-white.svg"} alt={''} />
                                    </EbakModalButton>
                                </div>

                                <HeadContentArrow/>
                            </div>
                        </InnerWrap>
                    </div>
                    <div className={Styles.MainContent}>
                        <Section isBg={true}>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.Article}>
                                        <div className={Styles.TextBlock}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                            >
                                                <h3>
                                                    <p>다양한 현장 경험
                                                        <span className={Styles.CountNum}>&nbsp;
                                                            <ViewCountUp
                                                                onEnterViewport={() => this.setState({isStart: true})}
                                                                isStart={this.state.isStart}/>
                                                        +
                                                    </span>
                                                    </p>
                                                    <p>3D랜더링 이미지로 디자인 확정</p>
                                                </h3>
                                            </ScrollAnimation>
                                        </div>
                                        <div className={Styles.ImageChanger}>
                                            <img className={Styles.BaseImage}
                                                 src={"/asset/img/randing/company_3danimation.svg"} alt={''} />
                                            <img className={Styles.OverImage}
                                                 src={"/asset/img/randing/company_section01_02.jpg"} alt={''} />
                                        </div>
                                        <div className={`${Styles.JustifyLayout} ${Styles.DoubleSlice}`}>
                                            <div className={`${Styles.IconAndText}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                >

                                                    <div className={Styles.Text}>

                                                        <h3 className={Styles.SubTitle}>
                                                            <span>현장 실측과 상담</span>
                                                        </h3>

                                                        <p className={Styles.RegularText}>
                                                            실측 날짜를 협의하여, 고객님이 계신 곳에서
                                                            편하게 상담 받으시고 자유롭게 선택하세요.
                                                        </p>

                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                                 >

                                                    <div className={Styles.Text}>

                                                        <h3 className={Styles.SubTitle}>
                                                            <span>견적서 확인 후 계약 진행</span>
                                                        </h3>

                                                        <p className={Styles.RegularText}>
                                                            실측한 것을 바탕으로 견적서를 작성하여 보내드려요.
                                                            충분히 확인하고 계약을 진행하세요.
                                                        </p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                                 >

                                                    <div className={Styles.Text}>

                                                        <h3 className={Styles.SubTitle}>
                                                            <span>시공받기</span>
                                                        </h3>

                                                        <p className={Styles.RegularText}>
                                                            시공 날짜에 정해진 시공기사가 방문하여 시공을 진행해요.
                                                            일정에 변동이 필요할 땐 미리 확인해서 알려주세요.
                                                        </p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                            <div className={`${Styles.IconAndText}`}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                                 >

                                                    <div className={Styles.Text}>

                                                        <h3 className={Styles.SubTitle}>
                                                            <span>검수 후 마무리</span>
                                                        </h3>

                                                        <p className={Styles.RegularText}>
                                                            시공 현장에 하자가 없는지 꼼꼼하게 확인 하셨나요?
                                                            검수가 끝나면 현장을 깔끔하게 정리하고 마무리 합니다.
                                                        </p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.Article}>
                                        <div className={`${Styles.TextAndImage}`}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                                             >
                                                <div className={Styles.TitleText}>
                                                    <h1>
                                                        <p>공장직영 기업의 단점을</p>
                                                        <p>이백인테리어로 해결하기</p>
                                                    </h1>
                                                    <div className={Styles.RegularText}>
                                                        <p>이백인테리어는 ‘사제 씽크대의 투박함’과 ‘레이아웃의 한계’를</p>
                                                        <p>보완하고 공장직영 기업의 장점을 전부 가진 새로운 기업이에요.</p>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                                             >
                                                <div className={Styles.Image}>
                                                    <img src={"/asset/img/randing/company_section02_01.jpg"} alt={''} />
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                        <div className={`${Styles.TextAndImage}`}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                                             >
                                                <div className={Styles.TitleText}>
                                                    <h1>
                                                        <p>현장실측 예약도</p>
                                                        <p>3초만에 끝!</p>
                                                    </h1>
                                                    <div className={Styles.RegularText}>
                                                        <p>이것 저것 묻고 따지지 않고 현장 실측 요청시</p>
                                                        <p>가장 빠른 날짜로 간편하게 실측을 예약할 수 있어요.</p>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                                             >
                                                <div className={Styles.Image}>
                                                    <img src={"/asset/img/randing/company_section02_02.jpg"} alt={''} />
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                        <div className={`${Styles.TextAndImage}`}>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                                             >
                                                <div className={Styles.TitleText}>
                                                    <h1>
                                                        <p>최고의 시공기술,</p>
                                                        <p>신뢰할 수 있는 A/S기간</p>
                                                    </h1>
                                                    <div className={Styles.RegularText}>
                                                        <p>20년째 씽크대, 붙박이 제작으로 공장 대표부터 시공 기사, 영업 직원, 일반 사원까지</p>
                                                        <p>모두가 제작 및 시공의 전문적인 지식을 보유하고 있는 기업이에요.</p>
                                                        <p>오랜 기간 공장을 운영하며 충분히 신뢰할 수 있는 A/S기간까지 보장해 드려요.</p>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                                             >
                                                <div className={Styles.Image}>
                                                    <img src={"/asset/img/randing/company_section02_03.jpg"} alt={''} />
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section isBg={true}>
                            <InnerWrap>
                                <div className={Styles.Content}>
                                    <div className={Styles.Article}>
                                        <div className={`${Styles.ImageAndText}`}>
                                            <div className={Styles.Image}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                                 >
                                                    <div className={Styles.LottieImages}>
                                                        <div className={Styles.LottieImage}>
                                                            <Lottie animationData={FreeConsulting} loop={true}/>
                                                        </div>
                                                        <div className={Styles.LottieImage}>
                                                            <Lottie animationData={FreeInspection} loop={true}/>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <p className={Styles.CountNumDown}>
                                                                    <span className={Styles.MiniFont}>총 금액</span>&nbsp;
                                                                    <ViewCountDown
                                                                        onEnterViewport={() => this.setState({isStartDown: true})}
                                                                        isStartDown={this.state.isStartDown}/><span className={Styles.MiniFont}>원</span>
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </ScrollAnimation>
                                            </div>

                                            <ScrollAnimation animateIn='fadeInUp'
                                                             animateOnce={true}
                                                             duration={0.5}
                                                             >
                                                <div className={Styles.TitleText}>
                                                    <h1>
                                                        <p>결제도</p>
                                                        <p>이백인테리어답게</p>
                                                    </h1>
                                                    <div className={Styles.RegularText}>
                                                        <p>시공이 확정된 후 결제가 진행되며,</p>
                                                        <p>시공상담이나 현장 점검 등으로 인한 비용이 전혀 발생하지 않아요.</p>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </InnerWrap>
                        </Section>
                        <Section>
                            <div className={Styles.InnerWrap}>
                                <div className={Styles.Content}>
                                    <div className={Styles.Article}>
                                        <div className={`${Styles.TextAndButton}`}>

                                            <div className={Styles.TextBlock}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                                 >
                                                    <h1>
                                                        <p>바로 예약 해보세요</p>
                                                    </h1>
                                                    <div className={Styles.RegularText}>
                                                        <p>계약 전에 현장 실측 후 예상 견적을 받아볼 수 있어요.</p>
                                                        <p>시공 여부는 견적을 받은 후에 충분히 고민해보고 결정하세요.</p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                            <div className={Styles.EbackButton}>
                                                <ScrollAnimation animateIn='fadeInUp'
                                                                 animateOnce={true}
                                                                 duration={0.5}
                                                                 >
                                                    <EbakModalButton
                                                        label={'현장상담 신청하기'}
                                                        navigateUrl={'/#/cost'}
                                                    >
                                                        <img className={Styles.EbackButtonIcon}
                                                             src={"asset/img/randing/iconsax/Call-Outline-white.svg"} alt={''} />
                                                    </EbakModalButton>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Section>
                    </div>

                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>


        )
            ;
    }
}

CompanyView.propTypes = {};

export default CompanyView;