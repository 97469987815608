import React from "react";
import Styles from "./EbakButton.module.scss";
import {observer} from "mobx-react";
import {navigate} from "../../helper/HistoryHelper";

@observer
class EbakButton extends React.Component {

    render() {
        let {label, navigateUrl, children} = this.props;
        return (
                <button className={Styles.EbakButton} onClick={()=> navigate(navigateUrl)}>
                    {children}
                    {label}
                </button>
        );
    }
}

export default EbakButton;