import React from "react";
import Styles from "./HeadContentArrow.module.scss";
import {observer} from "mobx-react";
import {ArrowDown2} from "iconsax-react";

@observer
class HeadContentArrow extends React.Component {

    render() {
        return (
                <div className={Styles.BottomArrow}>
                    <div>
                        <ArrowDown2 size='64' variant={'Outline'}/>
                    </div>
                </div>
        );
    }
}

export default HeadContentArrow;