import React, {Component} from 'react';
import {observer} from "mobx-react";
import Styles from "./PrivacyPolicyView.module.scss";
import InnerWrap from "../component/layout/InnerWrap";
import Logo from "../component/layout/Logo";
import appStore from "../store/AppStore";
import Section from "../component/layout/Section";
import Footer from "../component/layout/Footer";
import EbackFreeCall from "../component/layout/EbackFreeCall";
import {navigate} from "../helper/HistoryHelper";


@observer
class PrivacyPolicyView extends Component {

    componentDidMount() {
        window.scrollTo({ left: 0, top: 0, behavior: "instant" });

        var referenceNode = document.getElementsByTagName('script')[0];
        var newNode = document.createElement('script');
        newNode.setAttribute('type', 'text/javascript');
        newNode.setAttribute('id', 'talk_banner');
        newNode.src = 'https://ssl.pstatic.net/static.talk/bizmember/script/2022083100/js/banner/talk_banner.js';
        referenceNode.parentNode.insertBefore(newNode, referenceNode);
    }

    /*constructor(props) {
        super(props);
    }*/

    render() {

        return (
            <div>
                <nav className={Styles.Header}>
                    <InnerWrap>
                        <nav className={Styles.TopMenu}>
                            <Logo
                                logoName={'이백인테리어'}
                                logoNameColor={'#263238'}
                                doorColor={'#263238'}
                                iconNavigateUrl={'/main'}
                                characterNavigateUrl={'/main'}
                            />
                            <div className={`${Styles.Navigation} ${appStore.isOpen ? Styles.Active : ''}  `}>
                                <ul>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => window.open('/#/company')}>회사소개
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => window.open('/#/sink')}>자재선택
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => appStore.openModal()}>실측예약
                                        </div>
                                    </li>
                                    <li>
                                        <div className={Styles.LinkMenu}
                                             onClick={() => {
                                                 navigate('/search/photo')
                                                 window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                             }}>현장사진</div>
                                    </li>
                                </ul>
                                <EbackFreeCall />
                            </div>
                        </nav>
                    </InnerWrap>
                </nav>
                <main >
                    <div className={Styles.MainContent}>
                        <Section>
                            <InnerWrap>

                                <div className={`${Styles.Content} fs-5`}>
                                    <h3 className={`w-100 d-flex flex-row justify-content-center mb-5`}>개인정보처리방침</h3>

                                    <p className={`mb-3`}>제 1 조(목적)</p>
                                    <p className={`mb-5`}>이백인테리어(이하 ‘회사’라고 함)는 회사가 제공하고자 하는 서비스(이하 ‘회사 서비스'）를 이용하는 개인(이하 ‘이용자’ 또는 ‘개인'）의
                                        정보(이하 ‘개인정보'）를 보호하기 위해, 개인정보보호법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법'）등
                                        관련 법령을 준수하고, 서비스 이용자 의 개인정보 보호 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침
                                        (이하 ‘본 방침'）을 수립합니다.</p>

                                    <p className={`mb-3`}>제 2 조(개인정보 처리의 원칙)</p>
                                    <p className={`mb-5`}>개인정보 관련 법령 및 본 방침에 따라 회사는 이용자의 개인정보를 수집할 수 있으며 수집된 개인정보는 개인의 동의가 있는 경우에 한 해
                                        제3 자에게 제공될 수 있습니다.<br/>
                                        단, 법령의 규정 등에 의해 적법하게 강제되는 경우 회사는 수집한 이용자의 개인정보를 사전에 개인의 동의 없이 제3 자에게 제공할 수도 있습니다.</p>


                                    <p className={`mb-3`}>제 3 조(본 방침의 공개)</p>

                                    <p>1. 회사는 이용자가 언제든지 쉽게 본 방침을 확인할 수 있도록 회사 홈페이지 첫 화면 또는 첫 화면과의 연결화면을 통해 본 방침을 공개하고 있습니다.</p>
                                    <p className={`mb-5`}>2. 회사는 제1항에 따라 본 방침을 공개하는 경우 글자 크기, 색상 등을 활용하여 이용자가 본 방침을 쉽게 확인할 수 있도록 합니다.</p>


                                    <p className={`mb-3`}>제 4 조(본 방침의 변경)</p>
                                    <p>1. 본 방침은 개인정보 관련 법령, 지침, 고시 또는 정부나 회사 서비스의 정책이나 내용의 변경에 따라 개정될 수 있습니다.</p>
                                    <p>2. 회사는 제1항에 따라 본 방침을 개정하는 경우 다음 각 호 하나 이상의 방법으로 공지합니다.</p>
                                    <p>가. 회사가 운영하는 인터넷 홈페이지의 첫 화면의 공지사항란 또는 별도의 창을 통하여 공지하는 방법</p>
                                    <p className={`mb-2`}>나. 서면·모사전송·전자우편 또는 이와 비슷한 방법으로 이용자에게 공지하는 방법</p>
                                    <p className={`mb-5`}>3. 회사는 제2항의 공지는 본 방침 개정의 시행 일로부터 최소 7 일 이전에 공지합니다.<br/>
                                        다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30 일 전에 공지합니다.</p>


                                    <p className={`mb-3`}>제 5 조(회사 서비스 제공을 위한 정보)</p>

                                    <p className={`mb-2`}>회사는 이용자에게 회사의 서비스를 제공하기 위하여 다음과 같은 정보를 수집합니다.</p>
                                    <p className={`mb-5`}>필수 수집 정보: 이름 및 연락처</p>


                                    <p className={`mb-3`}>제 6 조(서비스 이용 및 부정 이용 확인을 위한 정보)</p>

                                    <p className={`mb-2`}>회사는 이용자의 서비스 이용 및 부정이용의 확인 및 분석을 위하여 다음과 같은 정보를 수집합니다.</p>
                                    <p className={`mb-2`}>필수 수집 정보: 서비스 이용 기록</p>
                                    <p className={`mb-5`}>※ 부정이용 : 회원 탈퇴 후 재가입, 상품 구매 후 구매 취소 등을 반복적으로 행하는 등 회사가 제공하는 할인쿠폰,
                                        이벤트 혜택 등의 경제상 이익을 불·편법적으로 수취하는 행위, 이용약관 등에서 금지하고있는 행위, 명의도용 등의
                                        불·편법 행위 등을 말합니다.<br/>
                                        수집되는 정보는 회사 서비스 이용에 따른 통계 분석에 이용될 수 있습니다.</p>


                                    <p className={`mb-3`}>제 7 조(기타 수집 정보)</p>

                                    <p className={`mb-2`}>회사는 아래와 같이 정보를 수집합니다.</p>
                                    <p>1. 수집 목적: 인테리어 상담</p>
                                    <p className={`mb-5`}>2. 수집 정보: 주소, 상담 내용</p>


                                    <p className={`mb-3`}>제 8 조(개인정보 수집 방법)</p>

                                    <p className={`mb-2`}>회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.</p>
                                    <p>1. 이용자가 회사의 홈페이지에 자신의 개인정보를 입력하는 방식</p>
                                    <p className={`mb-5`}>2. 이용자가 고객센터의 상담, 게시판에서의 활동 등 회사의 서비스를 이용하는 과정에서 이용자가 입력하는 방식</p>


                                    <p className={`mb-3`}>제 9 조(개인정보의 이용)</p>

                                    <p className={`mb-2`}>회사는 개인정보를 다음 각 호의 경우에 이용합니다.</p>
                                    <p>1. 회사의 서비스를 제공하기 위한 경우</p>
                                    <p className={`mb-5`}>2. 법령 및 회사 약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에
                                        지장을 주는 행위에 대한 방지 및 제재를 위한 경우</p>


                                    <p className={`mb-3`}>제 10 조(개인정보의 보유 및 이용 기간)</p>
                                    <p>1. 회사는 이용자의 개인 정보에 대해 개인정보의 수집·이용 목적이 달성을 위한 기간 동안 개인정보를 보유 및 이용합니다.</p>
                                    <p className={`mb-5`}>2. 전항에도 불구하고 회사는 내부 방침에 의해 서비스 부정 이용 기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 시점으로부터 최대 1년간 보관합니다.</p>

                                    <p className={`mb-3`}>제 11 조(법령에 따른 개인정보의 보유 및 이용 기간)</p>

                                    <p className={`mb-2`}>회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다.</p>
                                    <p>1. 전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유 정보 및 보유기간</p>
                                    <p>가. 계약 또는 청약철회 등에 관한 기록 :5 년</p>
                                    <p>나. 대금 결제 및 재화 등의 공급에 관한 기록 : 5 년</p>
                                    <p>다. 소비자의 불만 또는 분쟁처리에 관한 기록 :3 년</p>
                                    <p className={`mb-2`}>라. 표시·광고에 관한 기록 : 6개월</p>
                                    <p>2. 통신비밀 보호법에 따른 보유 정보 및 보유기간</p>
                                    <p className={`mb-2`}>가. 웹 사이트 로그 기록 자료 :3개월</p>
                                    <p>3. 전자금융거래법에 따른 보유 정보 및 보유기간</p>
                                    <p className={`mb-2`}>가. 전자금융거래에 관한 기록 : 5 년</p>
                                    <p>4. 위치 정보의 보호 및 이용 등에 관한 법률</p>
                                    <p className={`mb-5`}>가. 개인위치정보에 관한 기록 : 6개월</p>



                                    <p className={`mb-3`}>제 12 조(개인정보의 파기 원칙)</p>

                                    <p className={`mb-5`}>회사는 원칙적으로 이용자의 개인정보 처리 목적의 달성, 보유·이용 기간의 경과 등 개인정보가 필요하지 않을 경우에는 해당 정보를 지체 없이 파기합니다.</p>


                                    <p className={`mb-3`}>제 13 조(서비스 미 이용자에 대한 개인정보처리)</p>

                                    <p>1. 회사는 1 년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 원칙적으로 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장합니다.</p>
                                    <p>2. 회사는 장기 미이용 이용자의 개인정보는 별도로 분리되어 안전하게 보관하게 되며, 해당 이용자의 통지는 분리 보관 처리 일을 기준으로 최소 30 일 이전에 전자우편 주소로 전송됩니다.</p>
                                    <p>3. 장기 미이용 이용자는 회사가 미이용자 DB를 별도로 분리하기 전에 계속 서비스를 이용하고자 하는 경우 웹사이트(이하 '모바일앱' 포함)에 로그인하시면 됩니다.</p>
                                    <p>4. 장기 미이용 이용자는 웹사이트에 로그인할 경우 이용자의 동의에 따라 본인의 계정을 복원할 수 있습니다.</p>
                                    <p className={`mb-5`}>5. 회사는 분리 보관된 개인정보를 4년간 보관 후 지체 없이 파기합니다.</p>


                                    <p className={`mb-3`}>제 14 조(개인정보 파기절차)</p>

                                    <p>1. 이용자가 회원가입 등을 위해 입력한 정보는 개인정보 처리 목적이 달성된 후 별도의 DB로 옮겨져 (종이의 경우 별도의 서류함) 내부 방침 및 기타 관련
                                        법령에 의한 정보보호 사유에 따라(보유 및 이용 기간 참조) 일정 기간 저장된 후 파기 됩니다.</p>
                                    <p className={`mb-5`}>2. 회사는 파기 사유가 발생한 개인정보를 개인정보보호 책임자의 승인절차를 거쳐 파기 됩니다.</p>


                                    <p className={`mb-3`}>제 15 조(개인정보 파기방법)</p>

                                    <p className={`mb-5`}>회사는 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이로 출력된 개인정보는 분쇄기로
                                        분쇄하거나 소각 등을 통하여 파기합니다.</p>


                                    <p className={`mb-3`}>제 16 조(광고성 정보의 전송 조치)</p>

                                    <p>1. 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 이용자의 명시적인 사전동의를 받습니다.<br/>
                                        다만, 다음 각 호 어느 하나에 해당하는 경우에는 사전 동의를 받지 않습니다.</p>
                                    <p>가. 회사가 재화 등의 거래 관계를 통하여 수신자로부터 직접 연락처를 수집한 경우, 거래가 종료된 날로 부터 6개월 이내에 회사가 처리하고 수신자와
                                        거래한 것과 동종의 재화등에 대한 영리목적의 광고성 정보를 전송하려는 경우</p>
                                    <p className={`mb-2`}>나.「방문판매 등에 관한 법률」에 따른 전화권유 판매자가 육성으로 수신자에게 개인정보의 수집 출처를 고지하고 전화권유를 하는 경우</p>
                                    <p>2. 회사는 전항에도 불구하고 수신자가 수신거부 의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하지 않으며 수신거부 및 수신 동의 철회에 대한 처리 결과를 알립니다.</p>
                                    <p>3. 회사는 오후 9 시부터 그다음 날 오전 8시까지의 시간에 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우에는제 1항에도 불구하고 그 수신자로부터 별도의 사전 동의를 받습니다.</p>
                                    <p>4. 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 다음의 사항 등을 광고성 정보에 구체적으로 밝힙니다.</p>
                                    <p>가. 회사명 및 연락처</p>
                                    <p className={`mb-2`}>나. 수신거부 또는 수신 동의의 철회 의사 표시에 관한 사항의 표시</p>
                                    <p>5. 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 다음 각 호의 어느 하나에 해당하는 조치를 하지 않습니다.</p>
                                    <p>가. 광고성 정보 수신자의 수신거부 또는 수신 동의의 철회를 회피·방해하는 조치</p>
                                    <p>나. 숫자·부호 또는 문자를 조합하여 전화번호·전자우편 주소 등 수신자의 연락처를 자동으로 만들어 내는 조치</p>
                                    <p>다. 영리목적의 광고성 정보를 전송할 목적으로 전화번호 또는 전자우편 주소를 자동으로 등록하는 조치</p>
                                    <p>라. 광고성 정보 전송자의 신원이나 광고 전송 출처를 감추기 위한 각종 조치</p>
                                    <p className={`mb-5`}>마. 영리목적의 광고성 정보를 전송할 목적으로 수신자를 기망하여 회신을 유도하는 각종 조치</p>


                                    <p className={`mb-3`}>제 17 조(아동의 개인정보보호)</p>

                                    <p>1. 회사는 만 14세 미만 아동의 개인정보 보호를 위하여 만 14세 이상의 이용자에 한하여 회원가입을 허용합니다.</p>
                                    <p>2. 제1항에 도 불구하고 회사는 이용자가 만 14세 미만의 아동일 경우에는, 그 아동의 법정대리인으로부터 그 아동의 개인정보 수집, 이용, 제공 등의 동의를
                                        그 아동의 법정대리인으로부터 받습니다.</p>
                                    <p className={`mb-5`}>3. 제2항의 경우 회사는 그 법정대리인의 이름, 생년월일, 성별, 중복가입 확인정보(ID)，휴대폰 번호 등을 추가로 수집합니다.</p>


                                    <p className={`mb-3`}>제 18 조(개인정보 조회 및 수집 동의 철회)</p>

                                    <p>1. 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 개인정보 수집 동의 철회를 요청할 수 있습니다.</p>
                                    <p className={`mb-5`}>2. 이용자 및 법정 대리인은 자신의 가입 정보 수집 등에 대한 동의를 철회하기 위해서는 개인정보보호 책임자 또는 담당자에게 서면,
                                        전화 또는 전자우편 주소로 연락하시면 회사는 지체 없이 조치하겠습니다.</p>


                                    <p className={`mb-3`}>제 19 조(개인정보 정보 변경 등)</p>

                                    <p>1. 이용자는 회사에 전조의 방법을 통해 개인정보의 오류에 대한 정정을 요청할 수 있습니다.</p>
                                    <p className={`mb-5`}>2. 회사는 전항의 경우에 개인정보의 정정을 완료하기 전까지 개인정보를 이용 또는 제공하지 않으며 잘못된 개인정보를 제3자에게 이미
                                        제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</p>


                                    <p className={`mb-3`}>제 20 조(이용자의 의무)</p>

                                    <p>1. 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.</p>
                                    <p>2. 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.</p>
                                    <p className={`mb-5`}>3. 이용자는 전자우편 주소, 비밀번호 등에 대한 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.</p>


                                    <p className={`mb-3`}>제 21 조(회사의 개인정보 관리)</p>
                                    <p className={`mb-5`}>회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 훼손 등이 되지 아니 하도록 안전성을 확보하기 위하여 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.</p>


                                    <p className={`mb-3`}>제 22 조(삭제된 정보의 처리)</p>
                                    <p className={`mb-5`}>회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 회사가 수집하는 "개인정보의 보유 및 이용 기간"에 명시된 바에 따라 처리하고
                                        그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>


                                    <p className={`mb-3`}>제 23 조(비밀번호의 암호화)</p>

                                    <p className={`mb-5`}>이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.</p>


                                    <p className={`mb-3`}>제 24 조(해킹 등에 대비한 대책)</p>

                                    <p>1. 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해 이용자의 개인정보가 유출되거나 훼손되는 것 을 막기 위해 최선을 다하고 있습니다.</p>
                                    <p>2. 회사는 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출 또는 손상되지 않도록 방지하고 있습니다.</p>
                                    <p>3. 회사는 만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.</p>
                                    <p className={`mb-5`}>4. 회사는 민감한 개인정보를(수집 및 보유하고 있는 경우) 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</p>


                                    <p className={`mb-3`}>제 25 조(개인정보 처리 최소화 및 교육)</p>

                                    <p className={`mb-5`}>회사는 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보 처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부 방침 동의 준수를 강조하고 있습니다.</p>


                                    <p className={`mb-3`}>제 26 조(개인정보 유출 등에 대한 조치)</p>

                                    <p className={`mb-2`}>회사는 개인정보의 분실·도난·유출(이하 "유출 등"이라 한다) 사실을 안 때에는 지체 없이 다음 각 호의 모든 사항을 해당 이용자에게 알리고
                                        방송통신위원회 또는 한국 인터넷진흥원에 신고합니다.</p>
                                    <p>1. 유출 등이 된 개인정보 항목</p>
                                    <p>2. 유출 등이 발생한 시점</p>
                                    <p>3. 이용자가 취할 수 있는 조치</p>
                                    <p>4. 정보통신 서비스 제공자 등의 대응조치</p>
                                    <p className={`mb-5`}>5. 이용자가 상담 등을 접수할 수 있는 부서 및 연락처</p>


                                    <p className={`mb-3`}>제 27 조(개인정보 유출 등에 대한 조치의 예외)</p>

                                    <p className={`mb-5`}>회사는 전조에도 불구하고 이용자의 연락처를 알 수 없는 등 정당한 사유가 있는 경우에는 회사의 홈페이지에 30 일 이상 게시하는 방법으로
                                        전조의 통지를 갈음하는 조치를 취할 수 있습니다.</p>


                                    <p className={`mb-3`}>제 28 조(국외 이전 개인정보의 보호)</p>

                                    <p>1. 회사는 이용자의 개인 정보에 관하여 개인정보보호법 등 관계 법규를 위반하는 사항을 내용으로 하는 국제계약을 체결하지 않습니다.</p>
                                    <p>2. 회사는 이용자의 개인정보를 국외에 제공(조회되는 경우를 포함) 처리·위탁 보관(이하 '이전'이라 함)하려면 이용자의 동의를 받습니다.<br/>
                                        다만, 본조 제 3항 각 호의 사항 모두를 개인정보보호법등 관계 법규에 따라 공개하거나 전자우편 등 대통령령으로 정하는 방법에 따라 이용자에게 알린 경우에는
                                        개인정보 처리·위탁 보관에 따른 동의 절차를 거치지 아니할 수 있습니다.</p>
                                    <p>3. 회사는 본조 제 2항 본문에 따른 동의를 받으려면 미리 다음 각 호의 사항 모두를 이용자에게 고지합니다.</p>
                                    <p>가. 이전되는 개인정보 항목</p>
                                    <p>나. 개인정보가 이전되는 국가, 이전일시 및 이전방법</p>
                                    <p>다. 개인정보를 이전 받는 자의 성명(법인인 경우 그 명칭 및 정보관리 책임자의 연락처를 말한다)</p>
                                    <p className={`mb-2`}>라. 개인정보를 이전 받는 자의 개인정보 이용목적 및 보유·이용 기간</p>
                                    <p className={`mb-5`}>4. 회사는 본조 제2항 본문에 따른 동의를 받아 개인정보를 국외로 이전하는 경우 개인정보보호법 대통령령 등 관계 법규에서 정하는 바에 따라
                                        보호 조치를 합니다.</p>


                                    <p className={`mb-3`}>제 29 조(이용자의 쿠키 설치 선택권)</p>

                                    <p>1. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.<br/>
                                        따라서 이용자는 웹 브라우저에서 옵션을 설정함 으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br/>
                                        다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.</p>


                                    <p className={`mb-3`}>제 30 조(쿠키 설치 허용 지정 방법)</p>

                                    <p className={`mb-2`}>쿠키 설치 허용 여부를 지정하는 방법 (Internet Explorer 의 경우)은 다음과 같습니다.</p>
                                    <p>1.［도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</p>
                                    <p>2.［개인정보탭]을클릭 합니다.</p>
                                    <p className={`mb-5`}>3.［고급] 설정에서 설정하시면 됩니다.</p>


                                    <p className={`mb-3`}>제 31 조(회사의 개인정보 보호 책임자 지정)</p>

                                    <p className={`mb-2`}>회사는 이용자의 개인정보를 보호하고 개인 정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호 책임자를 지정하고 있습니다.</p>
                                    <p>가. 개인정보보호 책임자</p>
                                    <p>1) 성명: 유정은</p>
                                    <p>2) 전화번호: 010-4298-2102</p>
                                    <p className={`mb-2`}>3) 이메일: 200interior@gmail.com</p>
                                    <p>부칙</p>
                                    <p className={`mb-2`}>제 1 조 본 방침은 2021. 11. 22부터 시행됩니다.</p>
                                </div>
                            </InnerWrap>
                        </Section>
                    </div>
                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>


        )
            ;
    }
}


export default PrivacyPolicyView;