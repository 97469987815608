import React, {Component} from 'react';
import {Image} from 'react-bootstrap';
import _ from "lodash";
import {Img} from 'react-image';

class ThumbImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            images: [],
            index: 0
        }
    }

    componentDidMount() {

        const {src} = this.props;
        if (src) {
            let thumbnail = src.replace(".", "_thumb.");
            this.setState({
                images: [thumbnail, src]
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {src} = this.props;
        if (prevProps.src !== src && src) {
            let thumbnail =  src.replace(".", "_thumb.");
            this.setState({
                isError: false,
                images: [thumbnail, src]
            })
        }
    }
    render() {
        const {onLoad} = this.props;
        return (
            <>
                {
                    this.state.images.length > 0 ?
                        <Img
                            src={this.state.images[this.state.index]}
                            onLoad={onLoad}
                            onError={(e) => {
                                if (this.state.index < this.state.images.length - 1) {
                                    this.setState({
                                        index: this.state.index + 1
                                    })
                                } else {
                                    this.setState({
                                        isError: true
                                    })
                                }
                            }}
                        />
                        :
                        <></>
                }
            </>
        );
    }
}

export default ThumbImage;