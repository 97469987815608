import React from "react";
import Styles from "./Logo.module.scss";
import { HambergerMenu } from 'iconsax-react';
import appStore from "../../store/AppStore";
import {observer} from "mobx-react";
import {navigate} from "../../helper/HistoryHelper";

@observer
class Logo extends React.Component {

    render() {
        let {logoName, logoNameColor, doorColor, iconNavigateUrl, characterNavigationUrl} = this.props;
        return (
            <>
                <div className={Styles.Logo}>
                    <div className={Styles.LogoContainer}
                        onClick={()=>navigate(iconNavigateUrl)}
                    >
                        <img src="/asset/img/randing/logo152.png" alt="headerLogo"/>
                    </div>
                    <h5 style={{fontSize: '1.25rem', color:logoNameColor}}
                        onClick={()=>navigate(characterNavigationUrl)}
                    >{logoName}</h5>
                    <div className={Styles.EbackCall} onClick={()=> document.location.href='tel:010-4298-2102'}>
                        <img src={"/asset/img/randing/iconsax/Whatsapp-Outline-topBrand.svg"} alt={'전화걸기'}/>
                    </div>
                </div>
                <div className={Styles.NavigationMobileRight}
                     onClick={() => {
                         appStore.isOpen = !appStore.isOpen;

                     }}
                >
                    {appStore.isOpen ?
                        <HambergerMenu size={'2rem'} color={doorColor}/> :
                        <HambergerMenu size={'2rem'} color={doorColor}/>
                    }
                </div>
            </>
        );
    }
}

export default Logo;