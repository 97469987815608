import React, {Component} from 'react';
import Styles from './DetailPhotoView.module.scss';
import InnerWrap from "../component/layout/InnerWrap";
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";
import Logo from "../component/layout/Logo";

import Footer from "../component/layout/Footer";
import EbackFreeCall from "../component/layout/EbackFreeCall";
import appStore from "../store/AppStore";

import {makeObservable, observable} from "mobx";
import {Img} from 'react-image';
import axios from "axios";
import {APP_PUBLIC_PATH} from "../constant/AppConst";
import InfiniteScroll from "react-infinite-scroller";
import {PhotoStore, photoStore} from "../store/PhotoStore";
import {Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import {Call, Link, Star, Star1} from "iconsax-react";

import Lottie from "lottie-react";
import LoaderLottie from './data.json'
import {goBack, navigate} from "../helper/HistoryHelper";
import {FaSearch} from "react-icons/fa";
import {MdArrowBack, MdStar} from "react-icons/md";
import ThumbImage from "../component/image/ThumbImage";

@observer
class DetailPhotoView extends Component {

    constructor(props) {
        super(props);
        let {match:{params:{id}}} = props;
        this.state = {
            keyword: '',
            store : undefined
        }
    }

    componentDidMount() {

        let {match:{params:{id}}} = this.props;
        this.load(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match:{params:{id}}} = this.props;
        if(id != prevProps?.match?.params?.id){
            this.load(id);
        }
    }

    componentWillUnmount() {

    }

    load(id){
        let store = photoStore;
        let detailStore;

        if(store.detailStoreMap.has(id)){
            detailStore = store.detailStoreMap.get(id)
        }else{

            detailStore = new PhotoStore();
            detailStore.debounceRefresh();
            store.detailStoreMap.set(id, detailStore);
        }


        this.setState({store:detailStore},()=>{
            axios.post(`/image/detail/${id}`)
                .then(({data})=>{
                    this.state.store.selectedPhoto = data;
                })
            if(detailStore.nextPhoto){
                let target = document.getElementById(`DETAIL_${detailStore.nextPhoto?.id}`)
                target.scrollIntoView({  block: "center", inline: "nearest" });

            }else{
                window.scrollTo(0, detailStore.scrollTop)
            }
        })


    }

    render() {
        let store = this.state.store;
        return (
            <div className={Styles.PhotoView}>
                <div className={Styles.SearchHeader}>
                    <nav className={Styles.Header}>
                        <InnerWrap>
                            <nav className={Styles.TopMenu}>
                                <Logo
                                    logoName={'이백인테리어'}
                                    logoNameColor={'#263238'}
                                    doorColor={'#263238'}
                                    iconNavigateUrl={'/main'}
                                    characterNavigateUrl={'/main'}
                                />
                                <div className={`${Styles.Navigation} ${appStore.isOpen ? Styles.Active : ''}  `}>
                                    <ul>
                                        <li>

                                            <div className={Styles.LinkMenu} onClick={() => {
                                                navigate('/company')
                                                window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                            }}>회사소개
                                            </div>
                                        </li>
                                        <li>
                                            <div className={Styles.LinkMenu} onClick={() => {
                                                navigate('/sink')
                                                window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                            }}>자재선택
                                            </div>
                                        </li>
                                        <li>
                                            <div className={Styles.LinkMenu} onClick={() => appStore.openModal()}>실측예약

                                            </div>
                                        </li>
                                        <li>
                                            <div className={Styles.LinkMenu}

                                                 onClick={() => {
                                                     navigate('/search/photo')
                                                     window.scrollTo({left: 0, top: 0, behavior: "instant"})
                                                 }}>현장사진

                                            </div>
                                        </li>
                                    </ul>
                                    <EbackFreeCall/>
                                </div>
                            </nav>
                        </InnerWrap>
                    </nav>
                    <div className={Styles.SearchBar}>
                        <Container>
                            <InputGroup hasValidation className={`position-relative ${Styles.InputContainer}`}>
                                <div className={Styles.Icon}>

                                    <FaSearch/>
                                </div>
                                <Form.Control
                                    placeholder={'Search'}
                                    size={'lg'}
                                    value={photoStore.keyword}
                                    onChange={e => {
                                        photoStore.keyword = e.target.value
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            window.scrollTo(0, 0)
                                            store.searchCondition.set("keyword", photoStore.keyword)
                                            store.debounceFetch(true)
                                            navigate("/search/photo")
                                        }
                                    }}
                                    className={`${Styles.Input}`}

                                />
                                <Form.Control.Feedback type="invalid">

                                </Form.Control.Feedback>
                            </InputGroup>
                        </Container>
                    </div>
                    {/*<div className={Styles.BackButtonContainer}>*/}
                    {/*    <Button variant={"light"} className={Styles.BackButton}*/}
                    {/*            onClick={()=>{*/}
                    {/*                goBack();*/}
                    {/*            }}*/}
                    {/*    >*/}
                    {/*        <MdArrowBack size={`1.5rem`}/>*/}
                    {/*    </Button>*/}
                    {/*</div>*/}

                </div>
                <main>
                    <div className={Styles.PhotoContent}>
                        <Container>
                            <div className={Styles.Detail}>
                                <div className={Styles.Image}>
                                    <Img src={`${APP_PUBLIC_PATH}/${store?.selectedPhoto?.url}`}/>
                                </div>
                                <div className={Styles.Content}>
                                    <div className={Styles.BookMark}>
                                        <Button style={{color:'#fff'}}><Star1 className={`me-1`}/>즐겨찾기 추가</Button>
                                    </div>
                                    {store?.selectedPhoto?.blogUrl &&
                                        <div className={Styles.Link}>
                                            시공 블로그 보기 : <a href={store?.selectedPhoto?.blogUrl}>{store?.selectedPhoto?.blogUrl}</a>
                                        </div>
                                    }
                                    <div className={Styles.Title}>
                                        {store?.selectedPhoto?.subject}
                                    </div>
                                    <div className={Styles.Description}>
                                        {store?.selectedPhoto?.content}
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </Container>
                        {store &&
                            <div className={Styles.Scroll}>

                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={(page) => {
                                        store.debounceFetch(false)
                                    }}
                                    hasMore={store.photos.length < store.count}
                                    initialLoad
                                    threshold={250}
                                    loader={
                                        <div className="loader d-flex align-items-center justify-content-center" key={0}>
                                            <Lottie animationData={LoaderLottie} style={{width: 64, height: 64}}/>
                                        </div>
                                    }
                                    useWindow={true}

                                >
                                    <Container>
                                        <ResponsiveMasonry
                                            columnsCountBreakPoints={{576: 2, 768: 2, 992: 3, 1200: 4, 1400: 5}}

                                        >
                                            <Masonry className={Styles.MasonryContainer}>
                                                {
                                                    store.photos.map((photo, index) => {
                                                        let nextPhoto = store.nextPhoto;
                                                        return (
                                                            <div className={`${Styles.Item} ${photo?.id === nextPhoto?.id ? Styles.Active : ''}`}
                                                                 key={`DETAIL_${photo?.id}`}
                                                                 id={`DETAIL_${photo?.id}`}
                                                                 onClick={()=>{
                                                                     let id = photo?.id;
                                                                     store.nextPhoto = photo;
                                                                     store.scrollTop = window.scrollY;
                                                                     let detailStore;
                                                                     if(photoStore.detailStoreMap.has(id)) {
                                                                         detailStore = photoStore.detailStoreMap.get(id);
                                                                     }else {
                                                                         detailStore = new PhotoStore();
                                                                     }
                                                                     detailStore.selectedPhoto = photo;
                                                                     detailStore.scrollTop = 0;
                                                                     store.detailStoreMap.set(photo?.id, detailStore)
                                                                     navigate(`/photo/detail/${photo?.id}`)
                                                                 }}
                                                            >
                                                                <ThumbImage src={`${APP_PUBLIC_PATH}/${photo?.url}`} alt={''}
                                                                            style={{height:store.heightMap.get(photo?.id)||'auto'}}
                                                                            onLoad={e => {
                                                                                store.heightMap.set(photo?.id, e.target.height)
                                                                            }}
                                                                />
                                                                <div className={Styles.Cover}>
                                                                    {
                                                                        photo.blogUrl &&
                                                                        <Button variant={"light"} className={Styles.Link}
                                                                                onClick={() => {
                                                                                    window.open(photo.blogUrl, '_blank')
                                                                                }}
                                                                        >
                                                                            <Link variant="Bold"/>
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Masonry>
                                        </ResponsiveMasonry>
                                    </Container>
                                </InfiniteScroll>
                            </div>
                        }
                    </div>

                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>


        )
            ;
    }
}

DetailPhotoView.propTypes = {};

export default DetailPhotoView;