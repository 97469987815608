import React from "react";
import Styles from "./CollapseSample.module.scss";
import {observer} from "mobx-react";
import Collapse from 'react-bootstrap/Collapse';


@observer
class CollapseSample extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    render() {
        let {buttonLabel, children} = this.props;
        return (
            <>
                <button className={Styles.EbakButton}
                        onClick={() =>{
                            this.setState({isOpen:!this.state.isOpen})
                        }}
                >
                    <p>{buttonLabel}</p>
                    <div className={Styles.Icon}>
                        <img className={`${this.state.isOpen ? Styles.Active : ''}`}
                             src={"/asset/img/randing/iconsax/ArrowRight3-Outline-white.svg"} alt={''}/>
                    </div>
                </button>
                <Collapse in={this.state.isOpen}>
                    {children}
                </Collapse>
            </>
        )
            ;
    }
}

export default CollapseSample;