import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import DaumPostcode from 'react-daum-postcode';

class PostCodeStore {

    @observable
    show = false;
    @observable
    callback;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    open(callback) {
        this.callback = callback;
        this.show = true;
    }

    @action.bound
    close() {
        this.show = false;
    }
}

const postCodeStore = new PostCodeStore();

@observer
class PostCode extends Component {

    handleAddress = (data) => {
        let zipCode = data.zonecode;
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        postCodeStore.callback && postCodeStore.callback(zipCode, fullAddress, data);
        postCodeStore.close();
    };

    render() {
        let store = postCodeStore;
        return (
            <Modal
                centered
                show={store.show}
                onHide={() => store.close()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        주소 찾기
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body as={'div'}>
                    <div className={`d-flex`}>
                        <DaumPostcode
                            style={{height: 500}}
                            onComplete={this.handleAddress}
                        />
                    </div>
                </Modal.Body>

            </Modal>
        );
    }
}

export default PostCode;
export {postCodeStore};
