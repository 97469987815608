import React from "react";
import Styles from "./CubeScene.module.scss";
import {observer} from "mobx-react";


@observer
class CubeScene extends React.Component {

    render() {
        return (
            <div className={Styles.Scene}>
                <div className={Styles.Cube}>
                    <img src={"/asset/img/randing/cube_room/dress_cube_room001.png"} alt={''} />
                </div>
                <div className={Styles.ArrowText}>
                    <img className={Styles.First} src={"/asset/img/randing/cube_room/dress_cube_room002.svg"} alt={''} />
                </div>
                <div className={Styles.ArrowText}>
                    <img className={Styles.Second} src={"/asset/img/randing/cube_room/dress_cube_room003.svg"} alt={''} />
                </div>
                <div className={Styles.ArrowText}>
                    <img className={Styles.Third} src={"/asset/img/randing/cube_room/dress_cube_room004.svg"} alt={''} />
                </div>

            </div>
        );
    }
}

export default CubeScene;