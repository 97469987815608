import React from "react";
import Styles from "./InnerWrap.module.scss";
import {observer} from "mobx-react";

@observer
class InnerWrap extends React.Component {

    render() {
        let {children} = this.props;
        return (
                <div className={Styles.InnerWrap}>
                    {children}
                </div>
        );
    }
}

export default InnerWrap;