import React, {Component} from 'react';
import Styles from './EbackNotice.module.scss';
import {observer} from "mobx-react";
import HorizonLine from "../Decoration/HorizonLine";

@observer
class EbackNotice extends Component {

    componentDidMount() {
    }

    /*constructor(props) {
        super(props);
    }*/

    render() {

        return (
            <div className={Styles.Content}>
                <div className={Styles.ContentTitle}>
                    <h3 className={Styles.TextTitle}>
                        <p style={{color: '#009688'}}>Notice.</p>
                        <p>꼭 확인해주세요</p>
                    </h3>
                    <HorizonLine color={'#F7FAFC'} stroke={'10px'}/>
                </div>
                <div className={Styles.Article}>
                    <div className={`${Styles.JustifyLayout}`}>
                        <div className={`${Styles.IconTitleAndText}`}>
                            <div className={Styles.IconTitle}>
                                <div className={Styles.Icon}>
                                    <img src={"/asset/img/randing/iconsax/TaskSquare-Outline.svg"} alt={''} />
                                </div>
                                <h4 className={Styles.Text}>
                                    <p>유의사항</p>
                                </h4>
                            </div>
                            <div className={Styles.DescText}>
                                <div className={Styles.RegularText}>
                                    <p>화면상의 제품과 실제 제품간의 색상은 모니터 사양에 따라 다소 차이가 있을 수 있습니다.</p>
                                    <p>품질 개선과 성능의 향상을 위해 일부 사양 및 가격이 변동될 수 있습니다.</p>
                                    <p>고객님의 연락처가 정확하지 않은 경우 해피콜이 지연될 수 있습니다.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.IconTitleAndText}`}>
                            <div className={Styles.IconTitle}>
                                <div className={Styles.Icon}>
                                    <img src={"/asset/img/randing/iconsax/TruckFast-Outline.svg"} alt={''} />
                                </div>
                                <h4 className={Styles.Text}>
                                    <p>배송안내</p>
                                </h4>
                            </div>
                            <div className={Styles.DescText}>
                                <div className={Styles.RegularText}>
                                    <p>거리에 따라 물류, 시공비가 추가될 수 있습니다.</p>
                                    <p>현장 상황에 따른 장비 사용(엘리베이터/사다리차) 및 추가 발생 비용은 고객님 부담입니다.</p>
                                    <p>해당 제품은 공장에서 반조립 상태로 출고되며, 현장에서 시공되는 물건입니다.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.IconTitleAndText}`}>
                            <div className={Styles.IconTitle}>
                                <div className={Styles.Icon}>
                                    <img src={"/asset/img/randing/iconsax/Setting2-Outline.svg"} alt={''} />
                                </div>
                                <h4 className={Styles.Text}>
                                    <p>설치안내</p>
                                </h4>
                            </div>
                            <div className={Styles.DescText}>
                                <div className={Styles.RegularText}>
                                    <p>제품 설치 및 시공 공간을 사전에 확보해 주시기 바랍니다.</p>
                                    <p>철거 및 도배, 타일, 바닥 시공은 본시공 하루 전까지는 꼭 완료해 주시기 바랍니다.</p>
                                    <p>철거, 도배, 타일, 바닥 시공은 고객님께서 직접 진행하셔도 무방합니다.</p>
                                    <p>시공 최소 1주일 전에 관리사무소에 공사 신고, 입주민 동의, 엘리베이터 보양 작업 등 사전 준비사항을 꼭 진행해주시기 바랍니다.</p>
                                    <p>사전 준비사항은 고객님께서 직접 진행해주셔야 합니다.</p>
                                    <p>(현장별로 사전 준비사항이 상이하오니, 꼭 관리사무소에 미리 확인하시고 준비해주세요)</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.IconTitleAndText}`}>
                            <div className={Styles.IconTitle}>
                                <div className={Styles.Icon}>
                                    <img src={"/asset/img/randing/iconsax/Convertshape2-Outline.svg"} alt={''} />
                                </div>
                                <h4 className={Styles.Text}>
                                    <p>교환/반품 안내</p>
                                </h4>
                            </div>
                            <div className={Styles.DescText}>
                                <div className={Styles.RegularText}>
                                    <p>※ 청약 철회 및 계약 해지에 관한 내용</p>
                                    <p>본 제품은 주문제작 제품으로 전자상거래 등에서 소비자 보호에 관한 법률 시행령 제 21조에 따라 계약 이후에는 취소나 환불이 불가능한
                                        제품입니다.</p>
                                    <p>부득이하게 취소할 경우 당사 기준의 위약금이 발생합니다.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.IconTitleAndText}`}>
                            <div className={Styles.IconTitle}>
                                <div className={Styles.Icon}>
                                    <img src={"/asset/img/randing/iconsax/Whatsapp-Outline.svg"} alt={''} />
                                </div>
                                <h4 className={Styles.Text}>
                                    <p>유의사항</p>
                                </h4>
                            </div>
                            <div className={Styles.DescText}>
                                <div className={Styles.RegularText}>
                                    <p>제품 하자로 인한 품질보증 기간은 시공일로부터 1년입니다.</p>
                                    <p>품질보증기간이 지난 제품이라도 유상 A/S 문의 가능하오니 구매에 참고해주세요.</p>
                                    <p>A/S 접수 : 이백인테리어 고객센터 032-716-2228 또는 010-4298-2102</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

EbackNotice.propTypes = {};

export default EbackNotice;