import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Validation from './Validation';
import Styles from './Input.module.scss';
import {Form, InputGroup} from 'react-bootstrap';

/**
 * 오브젝트에서 지정한 키목록을 제거
 * @param {object} obj 변경하고자 하는 오브젝트
 * @param {...string} keys 지우고자 하는 키 목록
 * @return {object} 복제된 결과 오브젝트
 */
const omit = (obj, ...keys) => {
    let result = Object.assign({}, obj);
    keys.forEach(key => delete result[key]);
    return result;
};

/**
 * mobx 폼 스토어와 동기화 되는 인풋 필드.
 *
 * @reactProps {FormStore} store - mobx 스토어
 * @reactProps {string} name  - 필드 이름
 * @reactProps {*} value  - 필드 값
 * @reactProps {Array<Validation>} validations  - 유효성 체크목록
 */
@observer
class Input extends React.Component {

    constructor(props, context) {
        super(props, context);
        let {store, name, validations} = this.props;
        store.validationMap.set(name, validations);
        if (!store.valueMap.get(name))
            store.valueMap.set(name, '');
    }

    render() {
        const {store, name, validOnBlur, onChange, onBlur, /*label,*/ as = `input`, /*require = false,*/ readOnly = false, maxLength, placeholder} = this.props;
        const hasTried = store.hasTried.has(name);
        const errors = store.getErrorMessages(name);
        return (
            <>
                <div className={`d-flex flex-column flex-grow-1`}>
                    <InputGroup hasValidation className={`position-relative`}>
                        <Form.Control
                            placeholder={placeholder}
                            maxLength={maxLength}
                            as={as}
                            size={'lg'}
                            ref={ref => store.refs.set(name, ref)}
                            value={store.valueMap.get(name) || ''}
                            onChange={e => {
                                store.valueMap.set(name, e.target.value);
                                onChange && onChange(e);
                            }}
                            readOnly={readOnly}
                            onBlur={e => {
                                if (validOnBlur)
                                    store.hasTried.set(name, true);
                                onBlur && onBlur(e);
                            }}
                            className={`${Styles.Input}`}
                            isInvalid={hasTried && (errors && errors.length > 0)}
                            {...omit(this.props, 'store', 'value', 'validations', 'validOnBlur', 'onChange', 'onBlur', 'label')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors && errors.length > 0) && errors[0]}
                        </Form.Control.Feedback>
                    </InputGroup>

                </div>
            </>
        );
    }
}

Input.propTypes = {
    store      : PropTypes.object.isRequired,
    name       : PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    onChange   : PropTypes.func,
    onBlur     : PropTypes.func,
    validOnBlur: PropTypes.bool,
    value      : PropTypes.any,
    validations: PropTypes.arrayOf(PropTypes.instanceOf(Validation))
};

export default Input;
