import React from "react";
import Styles from "./TopPlateColorChip.module.scss";

class TopPlateColorChip extends React.Component{
    render() {
        let {colorName, children} = this.props;
        return(
            <div className={Styles.BaseColor}>
                {children}
                <div className={Styles.ColorName}>{colorName}</div>
            </div>
        );
    }
}

export default TopPlateColorChip;