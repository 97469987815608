import React from 'react';
import handleViewport from "react-in-viewport";


class Animation extends React.Component{
    render() {
        return(
            <>
                {this.props.children}
            </>
        )
    }
}

const Animate = handleViewport(Animation);

class Viewport extends React.Component{

    constructor(props) {
        super(props);
        this.state = {isStart: false};
    }

    render() {
        return(
            <Animate onEnterViewport={()=>{this.setState({isStart:true})}}
                     onLeaveViewport={()=>{this.setState({isStart:false})}}
            >
                {
                    React.cloneElement(

                        this.props.children,
                        {
                            ...this.props.children.props,
                            className:
                                this.state.isStart ?
                                    `${this.props.children.props.className} ${this.props.animationClass}`
                                    :
                                    this.props.children.props.className
                        }
                    )
                }
            </Animate>
        );
    }
}

export default Viewport;